import { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL, jwtTokenRef } from '../../httpContext';

const Invoices = ({ closeFunc }) => {
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${serverURL}/get-invoices`, {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        });

        // Extract invoices and sort them by date (most recent first)
        const sortedInvoices = response.data.data.sort(
          (a, b) => b.created - a.created,
        );

        setInvoices(sortedInvoices);
      } catch (err) {
        setError('Error fetching invoices. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div className='w-full h-full flex flex-col items-center justify-end bg-overlay-new-background box-border leading-normal tracking-normal text-left text-base text-dodgerblue font-montserrat'>
      <div className='rounded-t-xl rounded-b-none bg-white flex flex-col justify-between pt-5 pb-8 px-5 box-border gap-12 max-h-[80vh] overflow-y-auto w-full max-w-md mx-auto'>
        <div
          className='relative font-medium cursor-pointer self-end'
          onClick={closeFunc}
        >
          Close
        </div>
        <div className='w-full flex flex-col items-start justify-start'>
          <div className='w-full flex flex-col justify-start gap-12 text-lg text-updated-color-new-black'>
            <div className='flex flex-row items-end self-center justify-start gap-2.5'>
              <img
                className='h-8 w-9 relative'
                loading='lazy'
                alt=''
                src='/text-logo.svg'
              />
              <div className='relative font-semibold'>Your Invoices</div>
            </div>
            {error && (
              <div className='text-red-500 mt-2 text-center w-full'>
                {error}
              </div>
            )}
            {loading ? (
              <div className='w-full text-center'>Loading invoices...</div>
            ) : invoices.length > 0 ? (
              <ul className='w-full flex flex-col items-start justify-start gap-5'>
                {invoices.map((invoice) => (
                  <li
                    key={invoice.id}
                    className='flex flex-row items-center justify-between w-full border-b border-updated-color-grey1 py-2'
                  >
                    <div className='flex flex-col'>
                      <span className='font-medium'>Date:</span>{' '}
                      {new Date(invoice.created * 1000).toLocaleString()}
                    </div>
                    <div className='ml-1 mr-[25px]'>
                      <a
                        href={invoice.invoice_pdf}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='rounded-md bg-updated-color-blue flex items-center justify-center px-2 py-2 text-white cursor-pointer'
                      >
                        <div className='relative leading-[112.4%] font-medium'>
                          View Invoice PDF
                        </div>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='w-full text-center'>No invoices found.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
