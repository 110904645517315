import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { set } from 'date-fns';

const RecentsSidebar = () => {
  const navigate = useNavigate();
  const [recentFiles, setRecentFiles] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [fileIndex, setFileIndex] = useState(-1);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newFileName, setNewFileName] = useState('');

  const handleFileRename = async (transcriptId, newName) => {
    if (!newName) {
      setIsRenaming(false);
      return;
    }

    await axios.post(
      `${serverURL}/rename_transcript?transcript_id=${transcriptId}&new_name=${newName}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json', // Ensure the response is a blob
      },
    );
    setRecentFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === transcriptId) {
          return { ...file, name: newName };
        }
        return file;
      });
    });

    setNewFileName('');
    setIsRenaming(false);
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        console.log('fetching files');
        await jwtTokenRef.current;
        const response = await axios.get(`${serverURL}/list_transcripts`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: null, // Update as needed
            page_size: 10, // Update as needed
            page_number: 1, // Update as needed
            get_raw_transcript: false, // Update as needed
          },
        });

        const data = response.data.transcripts;
        console.log(data);
        const retrievedFiles = data.filter(
          (file) => file.transcript_type !== 'gpu' || file.transcript !== null,
        );
        setRecentFiles(retrievedFiles);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  return (
    <div className='flex flex-col rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff]'>
      <div className='relative leading-[22px] font-medium p-4 text-lg'>
        Recent
      </div>
      <div className='overflow-y-auto flex flex-col items-start justify-start py-5 px-[15px] box-border gap-[10px] text-base w-full max-h-96'>
        {recentFiles.map((file, index) => (
          <div
            key={file.id}
            className='w-full rounded-3xs bg-white flex flex-col py-1 pl-2.5 box-border gap-[2px] relative hover:text-white hover:bg-dodgerblue-200 duration-300 transition-all'
          >
            {openPopup && fileIndex === index && !isRenaming && (
              <RecentsPopup
                file={file}
                setOpenPopup={setOpenPopup}
                setIsRenaming={setIsRenaming}
                setRecentFiles={setRecentFiles}
              />
            )}
            <div
              className='flex items-center justify-between gap-[10px] cursor-pointer'
              onClick={() => {
                if (isRenaming) {
                  setOpenPopup(false);
                  setIsRenaming(false);
                }
                navigate(`/file_transcript?id=${file.id}`);
              }}
            >
              {!isRenaming || fileIndex !== index ? (
                <div className='leading-[22px] font-medium'>{file.name}</div>
              ) : (
                fileIndex === index && (
                  <input
                    type='text'
                    className='border-none outline-none bg-transparent font-medium p-0 text-base text-not-black w-11/12'
                    value={newFileName || file.name}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setNewFileName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setOpenPopup(false);
                        handleFileRename(file.id, newFileName);
                      }
                    }}
                  />
                )
              )}
              <div className='w-fit p-2'>
                {!isRenaming ? (
                  <MoreOutlined
                    className='text-xl'
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenPopup(true);
                      setFileIndex(index);
                    }}
                  />
                ) : (
                  fileIndex === index && (
                    <div
                      className='font-medium p-2 text-updated-color-blue'
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopup(false);

                        if (newFileName === file.name) {
                          setIsRenaming(false);
                          return;
                        }
                        handleFileRename(file.id, newFileName);
                      }}
                    >
                      Done
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RecentsPopup = ({
  file,
  setOpenPopup,
  setRecentFiles,
  setIsRenaming,
}) => {
  const handleDelete = async () => {
    try {
      await fetch(`${serverURL}/delete_transcript?transcript_id=${file.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      setRecentFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <div
      className='bg-white rounded-lg shadow-md flex flex-col w-52 absolute top-0 right-0 z-20 font-medium text-not-black'
      onMouseLeave={() => setOpenPopup(false)}
    >
      <div
        className='p-2.5 cursor-pointer hover:bg-sky-200 rounded-lg transition-all duration-300'
        onClick={() => setIsRenaming(true)}
      >
        Rename
      </div>
      <div
        className='p-2.5 cursor-pointer hover:bg-sky-200 rounded-lg transition-all duration-300'
        onClick={handleDelete}
      >
        Delete
      </div>
    </div>
  );
};

export default RecentsSidebar;
