import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userInfoRef } from '../httpContext';
import { isMobile } from 'react-device-detect';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

const Nav = ({ showMobile }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  const MobileMenuDropDown = () => {
    return (
      <div className='fixed top-0 left-0 w-full bg-white z-50 text-updated-color-blue font-montserrat'>
        <div className='flex relative px-4 md:px-16 mx-auto 2xl:px-0 items-center sm:gap-0 gap-5 py-8 pb-6 justify-between max-w-[1512px] min-h-8'>
          <div
            className='md:pl-2 cursor-pointer flex items-center gap-[5px]'
            onClick={() => {
              navigate('/');
            }}
          >
            <img
              className='h-6 sm:h-12 w-6 sm:w-12'
              alt=''
              src='/1024px-x-1024px-round-w-text1.svg'
            />
            <div className='tracking-[-0.02em] font-medium text-md sm:text-2xl'>
              interpret
            </div>
          </div>
          {!showMobileMenu && (
            <MenuOutlined
              className='text-2xl text-updated-color-grey2 stroke-[40px] stroke-updated-color-grey2'
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          )}
          {showMobileMenu && (
            <div className='absolute top-10 right-5 flex flex-col gap-4 text-xs sm:text-lg text-not-black-black bg-white shadow rounded-lg p-4 items-start'>
              <CloseOutlined
                className='text-lg absolute top-2 right-2'
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
              {!showMobile && (
                <div
                  className='flex items-center rounded-full p-2 px-3 justify-center'
                  onClick={() => {
                    navigate('/plan');
                    setShowMobileMenu(!showMobileMenu);
                  }}
                >
                  <div className='font-medium'>Pricing</div>
                </div>
              )}
              <div
                className='flex items-center rounded-full p-2 px-3 justify-center'
                onClick={() => {
                  if (userInfoRef.current?.verified_email)
                    navigate('/transcribe');
                  else {
                    navigate('/login');
                  }

                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <div className='font-medium'>
                  {isUserLoggedIn ? 'Dashboard' : 'Login'}
                </div>
              </div>
              <a
                className='block no-underline text-inherit flex font-medium items-center rounded-full p-2 px-3 justify-center'
                href='https://www.blog.interpretapp.ai/'
              >
                Blog
              </a>
              <div
                className='flex font-medium items-center rounded-full p-2 px-3 justify-center'
                onClick={() => {
                  navigate('/contact');
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Contact Us
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return !isMobile ? (
    <div className='fixed top-0 left-0 w-full bg-white/90 z-20 text-updated-color-blue font-montserrat'>
      <div className='flex mx-auto px-16 2xl:px-0 items-center sm:gap-0 gap-5 py-8 pb-6 justify-between max-w-[1512px]'>
        <div
          className='pl-2 cursor-pointer flex items-center gap-[5px]'
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            className='h-6 sm:h-12 w-6 sm:w-12'
            alt=''
            src='/1024px-x-1024px-round-w-text1.svg'
          />
          <div className='tracking-[-0.02em] font-medium text-md sm:text-2xl'>
            interpret
          </div>
        </div>
        <div className='pr-2 flex gap-8 text-xs sm:text-xl text-not-black-black'>
          <div
            className='flex font-semibold text-base items-center text-[#007EF3] rounded-full p-2 px-3 justify-center cursor-pointer  hover:bg-updated-color-blue/10 transition-all duration-500'
            onClick={() => navigate('/contact')}
          >
            Contact
          </div>
          {!showMobile && (
            <div
              className='flex font-semibold text-base text-[#007EF3] items-center rounded-full p-2 px-3 justify-center cursor-pointer  hover:bg-updated-color-blue/10 transition-all duration-500'
              onClick={() => {
                navigate('/plan');
              }}
            >
              Pricing
            </div>
          )}
          <a
            href='https://www.blog.interpretapp.ai/'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline'
          >
            <div className='flex font-semibold text-base text-[#007EF3] bg-sky-300/20 items-center rounded-xl p-2 px-8 py-5 justify-center cursor-pointer hover:bg-sky-200 transition-all duration-500'>
              How it works
            </div>
          </a>

          <div
            className='flex font-semibold text-base text-[#ffffff] bg-[#007EF3] items-center rounded-xl p-2 px-8 py-5 justify-center cursor-pointer hover:bg-[#2d65c0] transition-all duration-500 gap-2'
            onClick={() => {
              if (userInfoRef.current?.verified_email) navigate('/transcribe');
              else {
                navigate('/login');
              }
            }}
          >
            {isUserLoggedIn ? 'Dashboard' : 'Login'}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MobileMenuDropDown />
  );
};

export default Nav;
