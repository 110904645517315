import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';

const Plan = () => {
  const navigate = useNavigate();

  const onLoginTextClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loadUserPlan = async () => {
      if (userInfoRef.current && userInfoRef.current.email) {
        setIsLoggedIn(true);
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    };

    const interval = setInterval(loadUserPlan, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSubscribe = async (plan) => {
    let promoCode = '';

    if (!userInfoRef?.current) {
      navigate('/register');
    }

    // if the current plan already equals the plan the user is trying to subscribe to
    if (userInfoRef.current?.plan === plan) {
      return;
    }

    // Check if the current plan is either lite or pro
    if (
      userInfoRef.current?.plan === 'lite' ||
      userInfoRef.current?.plan === 'pro'
    ) {
      const promoCodeResponse = await fetch(serverURL + '/generate_discount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({
          // Add any required data for the verification here
        }),
      });

      if (promoCodeResponse.ok) {
        const data = await promoCodeResponse.json();
        promoCode = data.promo_code;
      }
    }

    const url = (() => {
      switch (plan) {
        case 'lite':
          return 'https://buy.stripe.com/bIY6pw8hv66B6Sk9AL';
        case 'pro':
          return 'https://buy.stripe.com/28og06btH66BdgIdQW';
        case 'standard':
          return 'https://buy.stripe.com/bIY5lsdBP8eJdgI3cm';
        default:
          return '';
      }
    })();

    const fullUrl = `${url}?prefilled_email=${userInfoRef.current?.email || ''}${promoCode ? '&prefilled_promo_code=' + promoCode : ''}`;

    const newWindow = window.open(fullUrl, '_blank');

    if (
      !newWindow ||
      newWindow.closed ||
      typeof newWindow.closed === 'undefined'
    ) {
      // Popup blocked, redirect instead
      window.location.href = fullUrl;
    }
  };

  return (
    <div className='w-full relative bg-white overflow-hidden text-left text-[1.25rem] text-not-black-black font-montserrat'>
      {/* Plans & Pricing Header */}
      <div className='mt-[9.188rem] flex flex-col items-center justify-start gap-[0.937rem] text-[2.5rem]'>
        <div className='relative leading-[123%] font-semibold'>{`Plans & Pricing`}</div>
        <div className='w-[40.75rem] relative text-[0.875rem] font-medium text-center inline-block'>
          Everything's within reach, no matter your plan. Enjoy full access to
          all features and content—the only difference is how long you can keep
          the fun going 🥳
        </div>
      </div>
      {/* Plans Section */}
      <div className='mt-[5rem] px-[2rem] flex flex-row items-start justify-center gap-[1.125rem] text-white flex-wrap'>
        {/* Free Plan */}
        <div className='w-[15.375rem] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-auto flex flex-col items-center justify-start py-[1.875rem] px-[1.25rem] box-border'>
          <div className='w-[13.5rem] flex flex-col items-center justify-start gap-[1.687rem]'>
            <div className='flex flex-col items-center justify-start'>
              <div className='w-[11.5rem] flex flex-col items-start justify-start gap-[1.25rem]'>
                <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[0.312rem] px-[1.875rem]'>
                  <div className='relative font-medium'>Free</div>
                </div>
                <b className='self-stretch relative text-[1.75rem] text-not-black-black'>
                  100 Credits
                </b>
              </div>
              <div className='w-[11.5rem] relative text-[0.875rem] text-not-black-black inline-block'>
                Enjoy 100 free credits for your first month. Unlock full access
                to all features - completely free.
              </div>
            </div>
            <div
              className='self-stretch rounded-[43px] flex flex-row items-center justify-center p-[0.625rem] text-center text-[0.906rem] cursor-pointer'
              style={{
                backgroundImage:
                  'linear-gradient(102.81deg, rgba(0, 126, 243, 0.6), rgba(121, 119, 209, 0.6))',
                borderWidth: '0.5px',
                borderColor: '#007ef3',
                borderStyle: 'solid',
              }}
              onClick={() => {
                if (!userInfoRef.current) {
                  navigate('/register');
                } else {
                  navigate('/transcribe');
                }
              }}
            >
              <div className='relative font-semibold'>Try for Free</div>
            </div>
          </div>
        </div>

        {/* Lite Plan */}
        <div className='w-[15.375rem] min-h-[17rem] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start py-[1.875rem] px-[1.25rem] box-border'>
          <div className='w-[13.5rem] flex flex-col items-center justify-start gap-[3.75rem]'>
            <div className='w-[10.625rem] flex flex-col items-start justify-start gap-[1.25rem]'>
              <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[0.312rem] px-[1.875rem]'>
                <div className='relative font-medium'>Lite</div>
              </div>
              <div className='self-stretch flex flex-row items-center justify-start gap-[0.375rem] text-[1.75rem] text-not-black-black'>
                <div className='relative'>
                  <b>$7.99</b>
                  <span className='font-medium'>/month</span>
                </div>
              </div>
              <div className='w-[4.813rem] relative text-[1rem] leading-[0.938rem] font-medium text-not-black-black inline-block shrink-0'>
                1000 credits
              </div>
            </div>
            {isLoggedIn && (
              <div
                className={`self-stretch rounded-[43px] bg-white flex flex-row items-center justify-center p-[0.625rem] text-center text-[0.906rem] ${userInfoRef.current?.plan === 'lite' ? 'bg-gray-200 text-gray-500' : 'cursor-pointer text-not-black-black'}`}
                onClick={() => {
                  if (userInfoRef.current?.plan !== 'lite') {
                    handleSubscribe('lite');
                  }
                }}
                style={{
                  borderWidth: '0.5px',
                  borderColor:
                    userInfoRef.current?.plan === 'lite'
                      ? '#cccccc'
                      : '#007ef3',
                  borderStyle: 'solid',
                }}
              >
                <div className='relative font-semibold'>
                  {userInfoRef.current?.plan === 'lite'
                    ? 'Current Plan'
                    : 'Buy Now'}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Standard Plan */}
        <div className='w-[15.375rem] min-h-[17rem] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center justify-start py-[1.875rem] px-[1.25rem] box-border'>
          <div className='w-[13.5rem] flex flex-col items-center justify-start gap-[3.75rem]'>
            <div className='w-[11.563rem] flex flex-col items-start justify-start gap-[1.25rem]'>
              <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[0.312rem] px-[1.875rem]'>
                <div className='relative font-medium'>Standard</div>
              </div>
              <div className='self-stretch flex flex-row items-center justify-start gap-[0.375rem] text-[1.75rem] text-not-black-black'>
                <div className='relative'>
                  <b>$15.99</b>
                  <span className='font-medium'>/month</span>
                </div>
              </div>
              <div className='w-[5rem] relative text-[1rem] leading-[0.938rem] font-medium text-not-black-black inline-block shrink-0'>
                2000 credits
              </div>
            </div>
            {isLoggedIn && (
              <div
                className={`self-stretch rounded-[43px] bg-white flex flex-row items-center justify-center p-[0.625rem] text-center text-[0.906rem] ${userInfoRef.current?.plan === 'standard' ? 'bg-gray-200 text-gray-500' : 'cursor-pointer text-not-black-black'}`}
                onClick={() => {
                  if (userInfoRef.current?.plan !== 'standard') {
                    handleSubscribe('standard');
                  }
                }}
                style={{
                  borderWidth: '0.5px',
                  borderColor:
                    userInfoRef.current?.plan === 'lite'
                      ? '#cccccc'
                      : '#007ef3',
                  borderStyle: 'solid',
                }}
              >
                <div className='relative font-semibold'>
                  {userInfoRef.current?.plan === 'standard'
                    ? 'Current Plan'
                    : 'Buy Now'}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Pro Plan (with BEST VALUE) */}
        <div className='relative w-[15.375rem] min-h-[17rem] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] box-border'>
          {/* BEST VALUE label */}
          <div className='absolute top-[-1.3rem] left-[0rem] rounded-t-xl rounded-b-none bg-updated-color-blue w-[15.375rem] flex flex-row items-center justify-center py-[0.4rem] px-[0.625rem] box-border text-[0.875rem] text-white'>
            <div className='relative font-semibold'>BEST VALUE</div>
          </div>
          {/* Rest of the Pro Plan card content */}
          <div className='flex flex-col items-center justify-start py-[1.875rem] px-[1.25rem] gap-[3.75rem] pt-[1.8rem]'>
            <div className='w-[12.063rem] flex flex-col items-start justify-start gap-[1.25rem] z-[0] text-left'>
              <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[0.312rem] px-[1.875rem]'>
                <div className='relative font-medium'>Pro</div>
              </div>
              <div className='self-stretch flex flex-row items-center justify-start gap-[0.375rem] text-[1.75rem] text-not-black-black'>
                <div className='relative'>
                  <b>$24.99</b>
                  <span className='font-medium'>/month</span>
                </div>
              </div>
              <div className='w-[5rem] relative text-[1rem] leading-[0.938rem] font-medium inline-block shrink-0 text-black'>
                4000 credits
              </div>
            </div>
            {isLoggedIn && (
              <div
                className={`self-stretch rounded-[43px] bg-white flex flex-row items-center justify-center p-[0.625rem] text-center text-[0.906rem] ${userInfoRef.current?.plan === 'pro' ? 'bg-gray-200 text-gray-500' : 'cursor-pointer text-not-black-black'}`}
                onClick={() => {
                  if (userInfoRef.current?.plan !== 'pro') {
                    handleSubscribe('pro');
                  }
                }}
                style={{
                  borderWidth: '0.5px',
                  borderColor:
                    userInfoRef.current?.plan === 'lite'
                      ? '#cccccc'
                      : '#007ef3',
                  borderStyle: 'solid',
                }}
              >
                <div className='relative font-semibold'>
                  {userInfoRef.current?.plan === 'pro'
                    ? 'Current Plan'
                    : 'Buy Now'}
                </div>
              </div>
            )}

            <div
              className='absolute top-[2rem] left-[8rem] rounded-[5px] flex flex-row items-center justify-center py-[0.312rem] px-[0.625rem] z-[2] text-[0.875rem]'
              style={{
                backgroundColor: 'rgba(255, 152, 18, 0.98)',
                margin: '0px',
              }}
            >
              <b className='relative'>Save 22%</b>
            </div>
          </div>
        </div>

        {/* Enterprise Plan */}
        <div className='w-[15.375rem] rounded-3xs [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-start justify-start py-[1.875rem] px-[1.25rem]'>
          <div className='flex flex-col items-center justify-start gap-[1.25rem]'>
            <div className='flex flex-col items-start justify-start gap-[0.312rem]'>
              <div className='self-stretch flex flex-col items-start justify-start gap-[1.25rem]'>
                <div className='w-[11.313rem] rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[0.312rem] px-[1.875rem] box-border'>
                  <div className='relative font-medium'>Enterprise</div>
                </div>
                <b className='relative text-[1.75rem] text-not-black-black'>
                  Contact Sales
                </b>
              </div>
              <div className='w-[10.625rem] relative text-[0.875rem] text-not-black-black inline-block'>
                Please contact us to start the service.
              </div>
            </div>
            <div
              className='cursor-pointer w-[13.438rem] rounded-[43px] bg-white box-border flex flex-row items-center justify-center p-[0.625rem] text-center text-[0.906rem] text-not-black-black'
              style={{
                borderWidth: '0.5px',
                borderColor: '#007ef3',
                borderStyle: 'solid',
              }}
              onClick={() => {
                navigate('/contact');
              }}
            >
              <div className='relative font-semibold'>Contact Sales</div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Section */}
      <div className='mt-[4rem] px-[8.563rem] text-[0.75rem] text-black'>
        <div className='font-semibold text-left text-[1.25rem] mb-4'>{`Features & How They Work`}</div>
        <div className='flex flex-row items-start justify-start flex-wrap gap-[1.062rem]'>
          {/* Feature Items */}
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-center justify-start py-[0.937rem] px-[0.625rem]'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.312rem]'>
              <div className='relative'>Text Translation</div>
              <div className='self-stretch relative font-semibold text-center flex items-end justify-center h-[2.188rem] shrink-0'>
                Free
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem]'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.312rem]'>
              <div className='relative'>Image Translation (Mobile only)</div>
              <div className='self-stretch relative font-semibold text-center flex items-end justify-center h-[2.188rem] shrink-0'>
                Free
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem]'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.312rem]'>
              <div className='relative'>Document Management</div>
              <div className='self-stretch relative font-semibold text-center flex items-end justify-center h-[2.188rem] shrink-0'>
                Free
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem]'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.312rem]'>
              <div className='relative'>{`AI Live Outline & Support`}</div>
              <div className='w-[1.75rem] relative font-semibold text-center flex items-end justify-center h-[2.188rem] shrink-0'>
                Free
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem]'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.312rem]'>
              <div className='relative'>Text to Speech</div>
              <div className='self-stretch relative font-semibold text-center flex items-end justify-center h-[2.188rem] shrink-0'>
                8 credits/hr
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border h-[5.344rem] flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem] text-center'>
            <div className='self-stretch flex flex-col items-center justify-start gap-[0.625rem]'>
              <div className='w-[10.75rem] relative inline-block'>{`Audio and Video File Transcription & Translation`}</div>
              <div className='relative font-semibold'>8 credits/hr</div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem] text-center'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start relative gap-[0.625rem]'>
              <div className='self-stretch relative z-[0]'>Broadcast Room</div>
              <div className='self-stretch relative font-semibold flex items-end justify-center h-[1.875rem] shrink-0 z-[1]'>
                40 credits/hr
              </div>
              <div className='!m-[0] absolute top-[-0.687rem] left-[-0.375rem] rounded-13xl bg-updated-color-blue flex flex-row items-center justify-center py-[0.125rem] px-[0.312rem] z-[2] text-white'>
                <div className='relative font-semibold'>New</div>
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem] text-center'>
            <div className='self-stretch flex flex-col items-center justify-start relative gap-[0.625rem]'>
              <div className='w-[9.063rem] relative inline-block z-[0]'>{`Real-Time Transcription & Translation`}</div>
              <div className='self-stretch relative font-semibold z-[1]'>
                40 credits/hr
              </div>
              <div className='!m-[0] absolute top-[-0.687rem] left-[-0.375rem] rounded-13xl bg-updated-color-blue flex flex-row items-center justify-center py-[0.125rem] px-[0.312rem] z-[2] text-white'>
                <div className='relative font-semibold'>New</div>
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem] text-center'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start gap-[0.375rem]'>
              <div className='relative'>Document Translation</div>
              <div className='w-[8.375rem] relative font-semibold flex items-end justify-center h-[2.125rem] shrink-0'>
                200 credits/10,000 words
              </div>
            </div>
          </div>
          <div className='flex-1 min-w-[14rem] rounded-8xs bg-white border-updated-color-blue border-[0.5px] border-solid box-border h-[5.344rem] flex flex-col items-start justify-start py-[0.937rem] px-[0.625rem] text-center'>
            <div className='self-stretch h-[3.438rem] flex flex-col items-center justify-start relative gap-[0.625rem]'>
              <div className='w-[8.813rem] relative inline-block z-[0]'>{`Zoom Transcription & Translation`}</div>
              <div className='self-stretch relative font-semibold z-[1]'>
                150 credits/hr
              </div>
              <div className='!m-[0] absolute top-[-0.687rem] left-[-0.375rem] rounded-13xl bg-updated-color-blue flex flex-row items-center justify-center py-[0.125rem] px-[0.312rem] z-[2] text-white'>
                <div className='relative font-semibold'>New</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-[4rem]'></div> {/* Bottom gap */}
    </div>
  );
};

export default Plan;
