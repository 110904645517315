import React, { useState } from 'react';
import { serverURL } from '../../httpContext';

export const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [status, setStatus] = useState({
    loading: false,
    success: null,
    error: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: null, error: null });

    try {
      const response = await fetch(`${serverURL}/request_customer_support`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          phone_number: formData.phoneNumber,
          message: formData.message,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setStatus({ loading: false, success: null, error: errorData.detail });
      } else {
        setStatus({
          loading: false,
          success: 'Customer support request received',
          error: null,
        });
        setFormData({ email: '', phoneNumber: '', message: '' });
      }
    } catch (error) {
      setStatus({
        loading: false,
        success: null,
        error: 'An unexpected error occurred.',
      });
    }
  };

  const formFields = [
    {
      label: 'Email (Optional)',
      name: 'email',
      type: 'email',
      required: false,
    },
    {
      label: 'Phone Number (Optional)',
      name: 'phoneNumber',
      type: 'tel',
      required: false,
    },
    { label: 'Message', name: 'message', type: 'textarea', required: true },
  ];

  return (
    <section className='py-28 max-w-[1512px] mx-auto flex flex-col gap-14 text-left text-2.5xl text-not-black-black font-montserrat'>
      {/* Header Section */}
      <div className='self-stretch flex flex-col gap-5'>
        <div className='flex flex-row'>
          <h1 className='mx-auto font-semibold text-center text-4xl'>
            Let’s Get in Touch
          </h1>
        </div>
        <div className='self-stretch font-medium text-center'>
          <p className='m-0 mx-2 sm:mx-0'>
            Have a question or need assistance? Reach out to us via email,
            phone, or the contact form below. We're eager to assist you.
          </p>
        </div>
      </div>

      {/* Form and Discord Button Container */}
      <div className='self-stretch flex flex-col items-center px-5 max-w-full text-lg'>
        {/* Contact Form */}
        <form
          className='w-full max-w-[29.188rem] flex flex-col gap-7'
          onSubmit={handleSubmit}
        >
          {formFields.map((field) => (
            <div key={field.name} className='self-stretch flex flex-col gap-2'>
              <label htmlFor={field.name} className='font-medium'>
                {field.label}
              </label>
              {field.type !== 'textarea' ? (
                <input
                  id={field.name}
                  name={field.name}
                  type={field.type}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  className='pl-2 border-none bg-transparent self-stretch h-[2.375rem] rounded-mini bg-gradient-to-r from-blue-100 to-indigo-100 text-lg'
                />
              ) : (
                <textarea
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  rows={5}
                  className='p-2 border-none bg-transparent self-stretch h-[15.063rem] rounded-mini bg-gradient-to-r from-blue-100 to-indigo-100 text-lg'
                />
              )}
            </div>
          ))}
          <button
            type='submit'
            className='w-fit text-not-black self-center p-2 px-3 rounded-[10px] border-not-black border border-solid text-lg font-medium disabled:opacity-50'
            disabled={status.loading}
          >
            {status.loading ? 'Submitting...' : 'Submit'}
          </button>
          {status.error && (
            <div className='text-red-500 text-center mt-2'>{status.error}</div>
          )}
          {status.success && (
            <div className='text-green-500 text-center mt-2'>
              {status.success}
            </div>
          )}
        </form>
        <a
          href='https://discord.gg/wqCfM6mjPS'
          target='_blank'
          rel='noopener noreferrer'
          className='p-2 px-3 shadow no-underline rounded-[10px] mt-10 text-lg font-medium bg-blue-500 text-white text-center hover:bg-blue-600 transition-colors'
        >
          Join our Discord
        </a>
      </div>
    </section>
  );
};
