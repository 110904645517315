import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../socketContext';
import { languageToCode, top100WorldLanguages } from '../../languages';
import {
  jwtTokenRef,
  serverURL,
  translateTexts,
  userInfoRef,
  checkFirstTimeLogin,
} from '../../httpContext';
import { format } from 'date-fns';
import axios from 'axios';
import RecentsSidebar from './RecentsSidebar';
import { mdiArrowDown } from '@mdi/js';
import Icon from '@mdi/react';
import StartJoinRoom from '../../components/StartJoinRoom';
import { OutOfCreditsWindow } from '../../components/OutOfCreditsWindow';
import ChatbotWidget from './ChatbotWidget';
import ReactMarkdown from 'react-markdown';
import { Switch } from '../../components/Switch';
import { playNextAudio, computeBlob } from '../../utils';

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const Transcribe = () => {
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const [isToggled, setIsToggled] = useState(false);
  const [outlineContent, setOutlineContent] = useState('');
  const [isUpdatingOutline, setIsUpdatingOutline] = useState(false);
  const chatbotRef = useRef();
  const isChatbotOpenRef = useRef(true);
  // const [isPaused, setIsPaused] = useState(false);
  // const [pausedTime, setPausedTime] = useState(0);
  const [showCopyOptions, setShowCopyOptions] = useState(false);
  const copyButtonPosition = useRef({ x: 0, y: 0 });

  // const handlePauseButton = () => {
  //   setIsPaused(!isPaused);
  //   if (!isPaused) {
  //     pauseTimer();
  //     stopRecording();
  //   } else {
  //     startTimer();
  //     startRecording();
  //   }
  // };

  const [showTutorial, setShowTutorial] = useState(false);
  const correctedMessagesShouldScroll = useRef(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [languageTwoDropdownVisible, setLanguageTwoDropdownVisible] =
    useState(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [showStartJoinARoomOverlay, setShowStartJoinARoomOverlay] =
    useState(false);
  const [hoveringEnterBroadcast, setHoveringEnterBroadcast] = useState(false);

  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const [isLoadingOutputTranslation, setIsLoadingOutputTranslation] =
    useState(false);
  const inputLanguage = useRef('Original');
  const [inputLanguageDisplay, setInputLanguageDisplay] =
    useState('Detect Language');
  const outputLanguage = useRef('Original');
  const [outputLanguageDisplay, setOutputLanguageDisplay] =
    useState('Original');
  const [tutorialStep, setTutorialStep] = useState(1);

  const [searchBarInput, setSearchBarInput] = useState(null);
  const [searchBarTwoInput, setSearchBarTwoInput] = useState(null);
  const inputLanguageRef = useRef(null);
  const outputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  const outputLanguageDropdownRef = useRef(null);

  const usingInputLanguagePopupRef = useRef(false);
  const usingOutputLanguagePopupRef = useRef(false);
  const [splitPosition, setSplitPosition] = useState(66); // Initial split at 66% for transcription

  const stoppedTranscribing = useRef(false);

  const isBroadcastingRef = useRef(false);
  const isInBroadcastRoomRef = useRef(false);

  const initMessages = [];
  const [messages, setMessages] = useState(initMessages);
  const [messagesMerged, setMessagesMerged] = useState(initMessages);
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);
  const messagesRef = useRef(messages);
  const [isTranscribing, setTranscribing] = useState(false);
  const isTranscribingRef = useRef(isTranscribing);
  const roomIdRef = useRef(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(true);
  const chatbotContextRef = useRef(null);

  const playingAudioRef = useRef(false);
  const liveAudioEnabledRef = useRef(false);
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);
  let blankSrc =
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  const audioQueueRef = useRef([]);
  const startProcessingIndexRef = useRef(messagesRef.current.length);

  const toggleLiveAudio = () => {
    if (!liveAudioEnabled) {
      // // Enable live audio
      audioContextRef.current.play();

      setLiveAudioEnabled(true);

      // Set the starting index to the current messages length
      let lastFinalMessage = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]['is_final']) {
          lastFinalMessage = i + 1;
          break;
        }
      }
      startProcessingIndexRef.current = lastFinalMessage;
    } else {
      // Disable live audio
      setLiveAudioEnabled(false);
      // Clear the audio queue and stop playback
      audioQueueRef.current = [];
      playingAudioRef.current = false;
      audioContextRef.current.pause();
    }
  };

  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  let tasks = [];

  {
    /*



OLD IMPLEMENTATION TO DETECT IF THE USER LOGGED IN FOR THE FIRST TIME, AND THEN START THE TUTORIAL.

I DON'T KNOW IF IT CAN EVEN DETECT IF THE USER LOGGED IN FOR THE FIRST TIME.

BUT THE TUTORIAL features WORK!


  useEffect(() => {
    const initTutorial = async () => {
      const isFirstLogin = await checkFirstTimeLogin();
      console.log("User info:", userInfoRef.current);
      console.log("Is first login:", isFirstLogin);
      
      if (isFirstLogin) {
        console.log("Showing tutorial for first-time user");
        setShowTutorial(true);
        const profileDropdown = document.querySelector('.profile-dropdown-trigger');
        if (profileDropdown) {
          console.log("Triggering profile dropdown");
          profileDropdown.click();
        }
      }
    };
    
    initTutorial();
  }, []);

*/
  }

  {
    /*


 TO SHOW TUTORIAL----- DONT DELETE

DONT DELETE
DONT DELETE
DONT DELETE
DONT DELETE


DONT DELETE
DONT DELETE
DONT DELETE
DONT DELETE



  const tutorialContent = {
    1: {
      title: "Credits Left",
      description: "Go to Profile to view the credits left",
      position: "top-0 right-[26.5rem]",
      boxSize: "w-80 h-[425px]",
      arrowPosition: "pt-[5.75rem] pl-[20rem]",
      arrowRotation: "rotate-0",
      buttonPosition: "pt-[4rem] gap-14 pl-[7.5rem]"
    },
    2: {
      title: "Buy Credits",
      description: "Go to Subscription to buy more credits and view the plan fits you the best.",
      position: "top-36 right-[26.5rem]",
      boxSize: "w-[23rem] h-[425px]",
      arrowPosition: "pt-[5.75rem] pl-[23rem]",
      arrowRotation: "rotate-0",
      buttonPosition: "pt-[4rem] gap-28 pl-[7.3rem]"
    },
    3: {
      title: "Broadcast Room",
      description: <div>As a <strong>host</strong> you can easily start and share the room with others and let everyone in that room read the script of the speech.<div style={{height: "2em"}}></div>As a <strong>guest</strong>, you can quickly join the room by scanning the code or link.</div>,
      position: "top-96 right-[60rem]",
      boxSize: "w-[30rem] h-[425px]",
      arrowPosition: "pt-[10rem] pl-[21.5rem] ",
      arrowRotation: "rotate-90",
      buttonPosition: "pb-[0.01rem] gap-52 pl-[7.3rem]  "
    }
  };
  
  const handleNextTutorial = () => {
    if (tutorialStep < Object.keys(tutorialContent).length) {
      setTutorialStep(tutorialStep + 1);
    } else {
      setShowTutorial(false);
    }
  };


  useEffect(() => {
    if (showTutorial) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showTutorial]);


*/
  }

  useEffect(() => {
    if (!liveAudioEnabledRef.current) {
      return;
    }

    if (tasks.length > 0) {
      return;
    }

    let textToAdd = '';
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      if (message['is_final']) {
        let text = message['transcript'];
        if (
          outputLanguage.current !== 'Original' &&
          message['corrected_transcripts'][outputLanguage.current] !== undefined
        ) {
          text = message['corrected_transcripts'][outputLanguage.current];
        }
        textToAdd = textToAdd + ' ' + text;
      }
    }
    if (textToAdd != '') {
      var blob = computeBlob(textToAdd, roomIdRef);
      tasks.push(blob);
    }

    let lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // wait for all the blobs to be computed
    Promise.all(tasks).then((blobs) => {
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      tasks = [];
      playNextAudio(
        playingAudioRef,
        audioQueueRef,
        audioContextRef,
        liveAudioEnabledRef,
      );
    });
  }, [messages, liveAudioEnabled]);

  // on page load, set the room id to the query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const roomId = queryParams.get('room_id');
    if (roomId) {
      roomIdRef.current = roomId;
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('chatbotClosed') === 'true') {
      setIsChatbotOpen(false);
    }
  }, []);

  const CopyOptionsPopup = ({ onSelect }) => {
    return (
      <div className='absolute bottom-10 right-0 bg-white shadow-lg rounded-md py-2 z-50 mt-[47rem] ml-[20rem]'>
        <div
          className='px-4 py-2 hover:bg-sky-200 cursor-pointer whitespace-nowrap'
          onClick={() => onSelect('original')}
        >
          Copy Original
        </div>
        <div
          className='px-4 py-2 hover:bg-sky-200 cursor-pointer whitespace-nowrap'
          onClick={() => onSelect('translated')}
        >
          Copy Translated
        </div>
        <div
          className='px-4 py-2 hover:bg-sky-200 cursor-pointer whitespace-nowrap'
          onClick={() => onSelect('both')}
        >
          Copy Both
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isToggled) {
      if (isTranscribing) setIsUpdatingOutline(true);
      else setIsUpdatingOutline(false);
      const updateOutlineInterval = setInterval(() => {
        if (chatbotRef.current) {
          const updatedOutline = chatbotRef.current.getOutlineContent();
          setOutlineContent(updatedOutline);
        }
      }, 5000); // Update every 5 seconds (adjust as needed)

      return () => clearInterval(updateOutlineInterval);
    }
  }, [isToggled, isTranscribing]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('loading zoom oauth callback');
    const code = queryParams.get('code');
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname;
      fetch(`${serverURL}/oauth-callback/zoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          // setHasZoomIntegration(true);
          // Handle success (e.g., redirect or update state)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const handleCopySelection = (type) => {
    let copiedTranscript = '';

    messagesMerged.forEach((message) => {
      const localTime = format(
        new Date(message.timestamp * 1000),
        'hh:mm:ss a',
      );

      switch (type) {
        case 'original':
          copiedTranscript += `${localTime}: ${message.transcript}\n\n`;
          break;
        case 'translated':
          copiedTranscript += `${localTime}: ${message.corrected_transcripts[outputLanguage.current] || message.transcript}\n\n`;
          break;
        case 'both':
          copiedTranscript += `${localTime}:\nOriginal: ${message.transcript}\nTranslated: ${message.corrected_transcripts[outputLanguage.current] || message.transcript}\n\n`;
          break;
      }
    });

    navigator.clipboard.writeText(copiedTranscript);
    setShowCopyOptions(false);
  };

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  const startTimer = () => {
    // Only set a new start time if we're starting fresh
    if (elapsedTime === 0) {
      setStartTime(new Date().getTime());
    } else {
      // If we have existing elapsed time, adjust start time to maintain it
      setStartTime(new Date().getTime() - elapsedTime);
    }
    setTimerRunning(true);
  };

  const pauseTimer = () => {
    setTimerRunning(false);
  };

  const updateMessagesMerged = (messagesUpdated) => {
    // copy the messagesUpdated array to avoid reference issues
    var messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
    var messagesMergedUpdated = [];
    var startOfMessagesToKeep = -1;
    for (var i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 4 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (var key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          if (messagesUpdatedCopy.length > 10 && startOfMessagesToKeep == -1) {
            startOfMessagesToKeep = i;
          }
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }

    if (messagesMergedUpdated.length > 0) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      lastMessageMerged['unconfirmed_transcript'] = '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
    }

    if (
      messagesUpdatedCopy.length > 0 &&
      !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
    ) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      var lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript']
          .length;
      lastMessageMerged['transcript'] = messagesMergedUpdated[
        messagesMergedUpdated.length - 1
      ]['transcript'].slice(0, -lengthOfLastMessage);
      lastMessageMerged['unconfirmed_transcript'] =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      for (var key in lastMessageMerged['corrected_transcripts']) {
        lengthOfLastMessage =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key].length;
        lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged[
          'corrected_transcripts'
        ][key].slice(0, -lengthOfLastMessage);
        lastMessageMerged['unconfirmed_corrected_transcripts'][key] =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key];
      }
    }

    // ensure only last 30 messages are stored
    if (messagesMergedUpdated.length > 15) {
      messagesMergedUpdated = messagesMergedUpdated.slice(
        messagesMergedUpdated.length - 15,
      );
      console.log('startOfMessagesToKeep: ' + startOfMessagesToKeep);

      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }

    console.log(messagesMergedUpdated);
    console.log(
      'messagesMergedUpdated: ' + JSON.stringify(messagesMergedUpdated),
    );
    setMessagesMerged(messagesMergedUpdated);
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
      if (usingOutputLanguagePopupRef.current) {
        if (
          outputLanguageRef.current &&
          !outputLanguageRef.current.contains(event.target)
        ) {
          if (
            outputLanguageDropdownRef.current &&
            !outputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingOutputLanguagePopupRef.current = false;
            setLanguageTwoDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    socket.removeAllListeners();
    console.log('adding listener for receive_transcriptions');
    socket.on('room', (data) => {
      isBroadcastingRef.current = true;
      isInBroadcastRoomRef.current = true;
      console.log('joined room ' + data);
    });

    if (roomIdRef.current) {
      console.log('joining room ' + roomIdRef.current);
    }

    socket.on('receive_transcriptions', async (data) => {
      console.log('received transcriptions ' + JSON.stringify(data));
      let messagesToAdd = [];
      let oldMessages = [...messagesRef.current];
      let message = data;

      let messageToAdd = {
        transcript_id: message['transcript_id'],
        transcript: message['transcript'],
        corrected_transcripts: message['corrected_transcripts'],
        is_final: message['is_final'],
        timestamp: message['timestamp'],
        diarization: message['diarization'],
        did_speaker_change: message['did_speaker_change'],
        is_ai_answer: message['is_ai_answer'],
      };

      let replacedMessage = false;
      for (let i = 0; i < oldMessages.length; i++) {
        if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
          oldMessages[i] = messageToAdd;
          replacedMessage = true;
          messagesToAdd = [];
        }
      }

      if (!replacedMessage) {
        messagesToAdd = [message];
      }

      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          shouldScroll.current = true;
        } else {
          setShouldShowScrollButton(true);
        }
      }

      setMessages([...oldMessages, ...messagesToAdd]);
      let messagesUpdated = [];
      for (let i = 0; i < oldMessages.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(oldMessages[i])),
        ];
      }
      for (let i = 0; i < messagesToAdd.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(messagesToAdd[i])),
        ];
      }
      console.log('messages updated: ' + JSON.stringify(messagesUpdated));

      var completeTranscript = '';
      for (var i = 0; i < messagesUpdated.length; i++) {
        completeTranscript += messagesUpdated[i]['transcript'];
      }

      chatbotContextRef.current = completeTranscript;

      updateMessagesMerged(messagesUpdated);

      messagesRef.current = [...oldMessages, ...messagesToAdd];
      // localStorage.setItem('messages', JSON.stringify(messagesUpdated));
    });

    return () => {
      console.log('Removing socket listeners');
      socket.removeAllListeners();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      const expiryDateEpochSeconds =
        Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
      socket.emit('save_audio', {
        jwt_token: jwtTokenRef.current,
        clear_audio: true,
        expiry_date_epoch_seconds: expiryDateEpochSeconds,
      });
    };
  }, [pathname]);

  const onTranscribe = async (blob) => {
    if (isTranscribingRef.current) {
      if (userInfoRef.current?.credits <= 10) {
        handleTranscribeButton();
        setTranscribing(false);
        setIsOutOfCreditsOpen(true);
        return {
          blob,
          text: '',
        };
      }
    }

    if (stoppedTranscribing.current) {
      clearChunks();
      stoppedTranscribing.current = false;
      return {
        blob,
        text: '',
      };
    }

    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    if (jwtTokenRef.current == undefined) {
      return;
    }

    var translateToLanguages = [];
    var possibleLanguages = [];

    if (
      inputLanguage.current != 'Detect Language' &&
      inputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(inputLanguage.current);
      if (languageToCode[inputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[inputLanguage.current]);
      }
    }

    if (
      outputLanguage.current != 'Detect Language' &&
      outputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(outputLanguage.current);
      if (languageToCode[outputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[outputLanguage.current]);
      }
    }

    if (possibleLanguages.length > 0) {
      socket.emit('possible_languages', [
        languageToCode[inputLanguage.current],
        languageToCode[outputLanguage.current],
      ]);
    }

    socket.emit('transcribe', {
      audio: base64,
      jwt_token: jwtTokenRef.current,
      translate_to_languages: translateToLanguages,
    });
    clearChunks();

    return {
      blob,
      text: '',
    };
  };

  const { stopRecording, startRecording, clearChunks } = useWhisper({
    onDataAvailable: onTranscribe,
    onTranscribe: onTranscribe,
    streaming: true,
    timeSlice: 250,
    removeSilence: false,
    whisperConfig: {
      language: 'en',
    },
  });

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      let lastScrollTop = messagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = messagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (messagesContainer) {
        messagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (messagesContainer) {
          messagesContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  const scrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (correctedMessagesShouldScroll.current) correctedScrollToBottom();
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged]);

  const handleInputLanguageClick = (language) => {
    if (language !== inputLanguage.current) {
      if (messagesRef.current.length) setIsLoadingTranslation(true);

      const textsToTranslate = [];
      const messagesToTranslateIndices = [];

      // Collect texts to translate and their indices
      messagesRef.current.forEach((message, index) => {
        if (message['corrected_transcripts'][language] === undefined) {
          textsToTranslate.push(message.transcript);
          messagesToTranslateIndices.push(index);
        }
      });

      // Proceed only if there are texts to translate
      if (textsToTranslate.length > 0) {
        translateTexts(textsToTranslate, language)
          .then((translations) => {
            const updatedMessages = [...messages]; // Create a shallow copy of messages

            // Assign translations to the correct messages
            messagesToTranslateIndices.forEach(
              (messageIndex, translationIndex) => {
                const translatedText = translations[translationIndex];
                // Ensure 'corrected_transcripts' exists
                if (!updatedMessages[messageIndex]['corrected_transcripts']) {
                  updatedMessages[messageIndex]['corrected_transcripts'] = {};
                }
                updatedMessages[messageIndex]['corrected_transcripts'][
                  language
                ] = translatedText.trim();
              },
            );
            setMessages(updatedMessages);
            updateMessagesMerged(updatedMessages);
          })
          .catch((error) => {
            console.error('Translation failed:', error);
            // Handle translation errors if necessary
          });
      } else {
        // If all messages are already translated, you might want to update the display immediately
        updateMessagesMerged(messages);
      }
    }

    // Update the language state regardless of whether translation was needed
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
    setLanguageDropdownVisible(false);
    setTimeout(() => {
      setIsLoadingTranslation(false);
    }, 1000);
  };

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  const handleOutputLanguageClick = (language) => {
    setLanguageTwoDropdownVisible(false);
    if (language != outputLanguage.current) {
      if (messagesRef.current.length) setIsLoadingOutputTranslation(true);

      var textsToTranslate = [];
      var messagesAlreadyTranslated = [];
      for (var i = 0; i < messagesRef.current.length; i++) {
        var message = messagesRef.current[i];
        console.log('message: ' + message);
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        var updatedMessages = [];
        for (var i = 0; i < messages.length; i++) {
          var message = messages[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }

            updatedMessages.push(message);
          }
        }
        setMessages(updatedMessages);
        updateMessagesMerged(updatedMessages);
        setIsLoadingOutputTranslation(false);
        // localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    outputLanguage.current = language;
    setOutputLanguageDisplay(language);
  };

  const handleTranscribeButton = async () => {
    if (userInfoRef.current?.credits <= 10) {
      setIsOutOfCreditsOpen(true);
      return;
    }

    if (isTranscribingRef.current) {
      stoppedTranscribing.current = true;
      isTranscribingRef.current = false;
      pauseTimer();
      await stopRecording();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
    } else {
      stoppedTranscribing.current = false;
      isTranscribingRef.current = true;
      await startRecording();
      // Set start time based on existing elapsed time
      setStartTime(new Date().getTime() - elapsedTime);
      startTimer();
    }
    setTranscribing(isTranscribingRef.current);
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    try {
      const response = await axios.post(
        `${serverURL}/add_bot_to_zoom`,
        { meeting_url: meetingUrl },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.status === 200) {
        console.log('Bot added to meeting');
        const { id, password } = response.data;
        navigate(`/broadcast/${id}?password=${password}`);
      } else {
        console.error('Failed to add bot to meeting');
      }
    } catch (error) {
      console.error('Error adding bot to meeting:', error);
    }
  };

  return (
    <div className='max-w-[1512px] mx-auto w-full relative text-left text-base text-updated-color-new-black font-montserrat flex flex-col 2xl:px-24'>
      {isOutOfCreditsOpen && (
        <OutOfCreditsWindow
          onClose={() => setIsOutOfCreditsOpen(false)}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            navigate('/buy_plan');
          }}
        />
      )}
      {showStartJoinARoomOverlay && (
        <StartJoinRoom
          onOutsideClickFunc={() => setShowStartJoinARoomOverlay(false)}
        />
      )}
      <div className='flex relative justify-center py-14 px-20 pb-28 h-screen'>
        <div className='shadow-inner border border-solid border-updated-color-grey1 rounded-lg w-full flex justify-between max-h-[680px] relative'>
          <div
            className='flex flex-col gap-8 w-full'
            style={{
              width: splitPosition > 70 ? '100%' : `${splitPosition}%`,
              position: splitPosition > 70 ? 'absolute' : 'relative',
              left: 0,
              top: 0,
              height: '100%',
              display: splitPosition < 30 ? 'none' : 'flex',
            }}
          >
            {languageTwoDropdownVisible && (
              <div
              ref={outputLanguageDropdownRef}
              className='grid grid-cols-4 gap-16 gap-y-10 pb-2 px-10 pr-20 bg-white border absolute top-20 border-solid border-gray-300 rounded-md shadow-lg z-[100] h-96 overflow-y-auto min-w-[600px] max-w-[600px]'
            >
                <div className='flex h-fit items-center col-span-4 p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                  <input
                    type='text'
                    placeholder='Search...'
                    className='w-full p-1 border-none outline-none text-lg font-montserrat'
                    value={searchBarTwoInput}
                    onChange={(e) => setSearchBarTwoInput(e.target.value)}
                  />
                  <img
                    className='w-5 h-5 ml-2'
                    alt=''
                    src='/general--search.svg'
                  />
                </div>
                {top100WorldLanguages
                  .filter((language) =>
                    language
                      .toLowerCase()
                      .includes(searchBarTwoInput?.toLowerCase() || ''),
                  )
                  .map((language) => (
                    <div
                      key={language}
                      className='p-2 hover:bg-gray-200 cursor-pointer w-fit h-fit'
                      onClick={() => handleOutputLanguageClick(language)}
                    >
                      {language}
                    </div>
                  ))}
              </div>
            )}

            {languageDropdownVisible && (
              <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-16 gap-y-10 pb-2 px-10 pr-20 bg-white border absolute top-20 border-solid border-gray-300 rounded-md shadow-lg z-[100] h-96 overflow-y-auto min-w-[600px] max-w-[600px]'
            >
                <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border border-gray-300'>
                  <input
                    type='text'
                    placeholder='Search...'
                    className='w-full p-1 border-none outline-none text-lg font-montserrat'
                    value={searchBarInput}
                    onChange={(e) => setSearchBarInput(e.target.value)}
                  />
                  <img
                    className='w-5 h-5 ml-2'
                    alt=''
                    src='/general--search.svg'
                  />
                </div>
                {top100WorldLanguages
                  .filter((language) =>
                    language
                      .toLowerCase()
                      .includes(searchBarInput?.toLowerCase() || ''),
                  )
                  .map((language) => (
                    <div
                      key={language}
                      className='hover:bg-gray-200 cursor-pointer w-full h-fit'
                      onClick={() => handleInputLanguageClick(language)}
                    >
                      {language}
                    </div>
                  ))}
              </div>
            )}
            <div className='relative overflow-hidden pb-24 h-full'>
              <div className=' font-montserrat font-semibold p-5 pl-5 flex items-center gap-4'>
                <div className='text-2xl'>Transcription</div>
                <span className='text-black font-[500] text-sm'>
                  {format(new Date(), 'MM.dd.yyyy, HH:mm a')}
                </span>
              </div>
              <div className='flex items-center justify-start gap-1 border-b border-updated-color-grey1'>
                <div
                  ref={inputLanguageRef}
                  className='w-fit rounded-34xl flex pb-1.5 pl-5 pr-1 cursor-pointer'
                  onClick={() => {
                    setLanguageDropdownVisible(!languageDropdownVisible);
                    usingInputLanguagePopupRef.current =
                      !usingInputLanguagePopupRef.current;
                  }}
                >
                  <div className='flex flex-col items-start justify-start pb-1.5 '>
                    <div className='flex flex-row items-start justify-start gap-[4px] cursor-pointer'>
                      {isLoadingTranslation && (
                        <svg
                          className='animate-spin h-5 w-5 self-center text-updated-color-blue'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-75'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className=''
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      )}
                      <b className='relative'>{inputLanguageDisplay}</b>
                      <img
                        className='w-6 relative h-6'
                        alt=''
                        src='/select-more-language.svg'
                      />
                    </div>
                  </div>
                </div>

                <div className='w-fit'>
                  <img
                    className='w-9 h-9 cursor-pointer'
                    alt=''
                    src='/switch.svg'
                    onClick={() => {
                      const temp = inputLanguage.current;
                      inputLanguage.current = outputLanguage.current;
                      outputLanguage.current = temp;
                      setInputLanguageDisplay(inputLanguage.current);
                      setOutputLanguageDisplay(outputLanguage.current);
                    }}
                  />
                </div>

                {isLoadingOutputTranslation && (
                  <div className='w-8 h-8 flex items-center justify-center'>
                    <svg
                      className='animate-spin h-7 w-7 text-updated-color-blue'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-75'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='10'
                      ></circle>
                      <path
                        className=''
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  </div>
                )}

                <div
                  ref={outputLanguageRef}
                  className='w-fit rounded-34xl flex flex-row items-start justify-start pb-1.5 pr-5  cursor-pointer'
                  onClick={() => {
                    usingOutputLanguagePopupRef.current =
                      !usingOutputLanguagePopupRef.current;
                    setLanguageTwoDropdownVisible(!languageTwoDropdownVisible);
                  }}
                >
                  <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
                    <div className='flex flex-row items-start justify-start gap-[4px]'>
                      <b className='relative'>{outputLanguageDisplay}</b>
                      <img
                        className='w-6 relative h-6'
                        alt=''
                        src='/select-more-language.svg'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='flex flex-col gap-4 overflow-y-auto h-[calc(100%-120px)] px-4'
                ref={messagesContainerRef}
              >
                <div className='flex gap-3 absolute bottom-1 right-4 w-fit z-10'>
                  <img
                    className='w-[34px] h-[34px] cursor-pointer hover:scale-150 transition-transform duration-300'
                    alt=''
                    src='/group-1707478175.svg'
                    onClick={() => setShowCopyOptions(!showCopyOptions)}
                  />
                  {showCopyOptions && (
                    <CopyOptionsPopup
                      position={copyButtonPosition}
                      onSelect={handleCopySelection}
                      onClose={() => setShowCopyOptions(false)}
                    />
                  )}
                  <div
                    onClick={() => {
                      socket.emit('save_audio', {
                        jwt_token: jwtTokenRef.current,
                      });
                      setShowSavedMessage(true);
                      setTimeout(() => setShowSavedMessage(false), 2000);
                    }}
                    className='cursor-pointer px-3 py-1.5 flex h-fit items-center bg-white shadow rounded-full text-sm transform hover:scale-120 transition-all duration-300'
                  >
                    {showSavedMessage ? 'Saved!' : 'Save'}
                  </div>
                </div>
                {messagesMerged.map((message, index) => {
                  const localTime = format(
                    new Date(message.timestamp * 1000),
                    'hh:mm:ss a',
                  );
                  return (
                    <div key={index} className='flex flex-col m-1'>
                      {' '}
                      {/* Changed from flex items-start to flex flex-col */}
                      <span className='mb-2 text-gray-600 text-smi'>
                        {localTime}
                      </span>{' '}
                      {/* Changed from mr-4 to mb-2 */}
                      {outputLanguage.current !== 'Original' && outputLanguage.current !== 'Detect Language' && (
        <div className='flex gap-4 w-fit px-2 rounded-md bg-[#b3d8fb] text-sm'>
          <p className='inline'>
            {message.corrected_transcripts[inputLanguage.current] || message.transcript}
            <span className='inline text-gray-500'>{message.unconfirmed_transcript}</span>
          </p>
        </div>
      )}
                      <div key={index} className='flex items-start '>
                        <p className='inline rounded-md bg-[#ffffff] border-solid text-sm border-[#D9D9D9] border-[.5px] p-3'>
                          {outputLanguage.current === 'Original' ||
                          outputLanguage.current === 'Detect Language' ||
                          !message.corrected_transcripts[outputLanguage.current]
                            ? message.transcript
                            : message.corrected_transcripts[
                                outputLanguage.current
                              ]}
                          <span className='inline text-gray-500 text-sm'>
                            {outputLanguage.current === 'Original' ||
                            outputLanguage.current === 'Detect Language' ||
                            !message.unconfirmed_corrected_transcripts?.hasOwnProperty(
                              outputLanguage.current,
                            )
                              ? message.unconfirmed_transcript
                              : message.unconfirmed_corrected_transcripts[
                                  outputLanguage.current
                                ]}
                          </span>
                        </p>
                      </div>
                      <div ref={outputLanguageDropdownRef}></div>
                    </div>
                  );
                })}
                <div className='absolute bottom-0 left-0 right-0 mx-auto flex flex-col gap-4 items-center justify-center'>
                  {shouldShowScrollButton && (
                    <div
                      className='w-fit cursor-pointer'
                      onClick={scrollToBottom}
                    >
                      <Icon path={mdiArrowDown} size={1} />
                    </div>
                  )}
                  <div className='flex flex-row items-center justify-center text-center text-xs w-[95%] pb-14'>
                    <div className='flex flex-col items-start justify-start py-2.5 px-0 w-full'>
                      <img
                        className='w-full relative max-h-full'
                        alt=''
                        src='/vector-367.svg'
                      />
                    </div>
                    <div className='w-12 relative flex items-center justify-center shrink-0 backdrop-blur-sm bg-white/30 rounded-lg p-2'>
                      {formatTime(elapsedTime)}
                    </div>
                    <div className='flex flex-col items-start justify-start py-2.5 px-0 w-full'>
                      <img
                        className='w-full relative max-h-full'
                        alt=''
                        src='/vector-367.svg'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='absolute bottom-4 left-[20px]'>
                <Switch
                  isOn={liveAudioEnabled}
                  handleToggle={toggleLiveAudio}
                  onColor='bg-dodgerblue-200'
                  text={splitPosition > 42 ? 'Live Audio' : ''} // Only show text if panel is wide enough
                />
              </div>
              <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex gap-[2px]'>
                {!isTranscribing && (
                  <img
                    className='w-[34px] h-[34px] cursor-pointer hover:scale-150 transition-transform duration-300'
                    alt=''
                    src='/group-1707478176.svg'
                    onClick={() => {
                      handleTranscribeButton();
                    }}
                  />
                )}
                {isTranscribing && (
                  <img
                    className='w-[34px] h-[34px] cursor-pointer  hover:scale-150 transition-transform duration-300'
                    alt=''
                    src='/group-1707478181.svg'
                    onClick={() => {
                      handleTranscribeButton();
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className='fixed bottom-20 left-0 rounded-tl-none rounded-tr-11xl rounded-br-11xl rounded-bl-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-11 flex flex-row items-center justify-center py-2.5 pr-5 pl-[49px] box-border gap-[5px] cursor-pointer w-fit'
              onClick={() => {
                setShowStartJoinARoomOverlay(true);
              }}
              onMouseOver={() => {
                setHoveringEnterBroadcast(true);
              }}
              onMouseOut={() => {
                setHoveringEnterBroadcast(false);
              }}
            >
              <div
                className={`font-semibold transition duration-500 ${hoveringEnterBroadcast && 'scale-125 -translate-x-5'}`}
              >
                Enter Broadcast
              </div>
              <img
                className={`w-6 h-6 transition duration-500 ${hoveringEnterBroadcast && '-rotate-45'}`}
                src='/arrow--arrow-right-1.svg'
              />
            </div>
          </div>

          <div
            className="w-[0.2rem] cursor-col-resize mt-4 bg-[#E7E7E7] h-[95%] hover:bg-blue-400 active:bg-blue-600 transition-colors before:content-[''] before:absolute before:top-1/2 before:-translate-y-1/2 before:left-1/2 before:-translate-x-1/2 before:w-4 before:h-14 before:bg-[#E7E7E7] before:rounded-[25px] before:hover:bg-blue-400 before:active:bg-blue-600 before:transition-colors after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:left-1/2 after:-translate-x-1/2 after:w-[0.01rem] after:h-8 after:flex after:justify-around after:items-center after:px-1 after:[background:linear-gradient(to_right,#a8a8a8_33%,transparent_33%,transparent_66%,#a8a8a8_66%)] after:active:[background:linear-gradient(to_right,#fff_33%,transparent_33%,transparent_66%,#fff_66%)] after:hover:bg-blue-400 after:active:bg-blue-600 after:transition-colors after:flex after:justify-around after:items-center after:px-1 after:[background:linear-gradient(to_right,#666_33%,transparent_33%,transparent_66%,#666_66%)] after:hover:[background:linear-gradient(to_right,#fff_33%,transparent_33%,transparent_66%,#fff_66%)] after:bg-[length:2px_100%] after:bg-no-repeat"
            style={{
              position: 'absolute',
              left:
                splitPosition < 30
                  ? '0%'
                  : splitPosition > 70
                    ? '100%'
                    : `${splitPosition}%`,
              zIndex: 50,
            }}
            onMouseDown={(e) => {
              const container = e.target.parentElement;
              const containerRect = container.getBoundingClientRect();

              const startDrag = (e) => {
                // Calculate the new position relative to the container
                const offsetX = e.clientX - containerRect.left;
                const containerWidth = containerRect.width;
                const newPosition = (offsetX / containerWidth) * 100;

                // Handle snapping logic
                if (splitPosition < 30 && newPosition > 1) {
                  setSplitPosition(50);
                  return;
                }
                if (splitPosition > 70 && newPosition < 99) {
                  setSplitPosition(50);
                  return;
                }

                // Constrain the position between 20% and 80%
                setSplitPosition(Math.min(Math.max(newPosition, 20), 80));
              };

              const stopDrag = () => {
                document.removeEventListener('mousemove', startDrag);
                document.removeEventListener('mouseup', stopDrag);
              };

              document.addEventListener('mousemove', startDrag);
              document.addEventListener('mouseup', stopDrag);
            }}
          />

          <div
            className='flex flex-col gap-8 relative'
            style={{
              width:
                splitPosition < 30
                  ? '100%'
                  : splitPosition > 70
                    ? '0%'
                    : `${100 - splitPosition}%`,
              position: splitPosition < 30 ? 'absolute' : 'relative',
              left: 0,
              top: 0,
              height: '100%',
              display: splitPosition > 70 ? 'none' : 'flex',
            }}
          >
            <div className='flex flex-col h-full'>
              <div className='flex items-center gap-4 p-5 border-b border-updated-color-grey1'>
                <span className='text-2xl font-montserrat font-semibold'>
                  Note Taker
                </span>
                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    className='sr-only peer'
                    checked={isToggled}
                    onChange={() => setIsToggled(!isToggled)}
                  />

                  <div className="w-11 h-6 bg-[#454545] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['OFF'] after:flex after:items-center after:justify-center after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:content-['ON'] after:font-montserrat after:font-semibold after:text-[8px] after:text-black/50 peer-checked:bg-gradient-to-r peer-checked:from-[#75b1f4] peer-checked:to-[#a2ace7]"></div>
                </label>
                {isUpdatingOutline && (
                  <svg
                    className='animate-spin h-6 w-6 self-center text-updated-color-blue'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-75'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className=''
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                )}
                {isUpdatingOutline && (
                  <div className='text-updated-color-blue font-medium'>
                    Updating...
                  </div>
                )}
              </div>
              <div className='p-5 pt-0 pl-0 overflow-y-auto text-sm'>
                {isToggled && <ReactMarkdown>{outlineContent}</ReactMarkdown>}
              </div>
            </div>
          </div>
          {splitPosition < 30 && (
            <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex gap-[2px]'>
              {!isTranscribing && (
                <img
                  className='w-[50px] h-[50px] cursor-pointer hover:scale-150 transition-transform duration-300'
                  alt=''
                  src='/group-1707478176.svg'
                  onClick={() => {
                    handleTranscribeButton();
                  }}
                />
              )}
              {isTranscribing && (
                <img
                  className='w-[50px] h-[50px] cursor-pointer hover:scale-150 transition-transform duration-300'
                  alt=''
                  src='/group-1707478181.svg'
                  onClick={() => {
                    handleTranscribeButton();
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className='flex flex-col gap-4 ml-14 min-w-80 max-w-80'>
          <div className='flex flex-col justify-center relative w-full gap-1'>
            <img
              src='/youtube.png'
              alt=''
              className='w-6 h-6 absolute top-2 left-2'
            />
            <input
              placeholder='Paste Youtube URL'
              className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  try {
                    const response = await axios.post(
                      `${serverURL}/transcribe_youtube?youtube_url=${encodeURIComponent(e.target.value)}`,
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${jwtTokenRef.current}`,
                        },
                      },
                    );
                    const { transcript_id } = response.data;
                    navigate(`/file_transcript?id=${transcript_id}`);
                  } catch (error) {
                    console.error('Error transcribing YouTube video:', error);
                  }
                }
              }}
            />
          </div>
          <div className='flex flex-col justify-center relative w-full gap-1'>
            <img
              src='/cam.svg'
              alt=''
              className='w-6 h-6 absolute top-2 left-2'
            />
            <input
              placeholder='Paste Meeting URL'
              className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addBotToMeeting(e.target.value);
                }
              }}
            />
            <div className='flex items-center text-[#c5c5c5] font-bold self-end gap-1'>
              Supports
              <img
                src='/platforms.svg'
                alt='Meeting platform logos'
                className='w-20'
              />
            </div>
          </div>
          <RecentsSidebar />
        </div>
      </div>
      <div style={{ display: isChatbotOpen ? 'block' : 'none' }}>
        <ChatbotWidget
          ref={chatbotRef}
          isChatbotOpenRef={isChatbotOpenRef} // Pass the ref object
          onClose={() => {
            localStorage.setItem('chatbotClosed', 'true');
            setIsChatbotOpen(false);
            isChatbotOpenRef.current = false; // Update the ref value
          }}
          contextRef={chatbotContextRef}
          isToggled={isToggled}
        />
      </div>
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='chai2.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}

      {/*


TO SHOW TUTORIAL----- DONT DELETE

DONT DELETE
DONT DELETE
DONT DELETE
DONT DELETE


{showTutorial && (
  <div className="fixed inset-0 bg-black/50 z-[100] flex items-center justify-center">
    <div className={`absolute ${tutorialContent[tutorialStep].position} ${tutorialContent[tutorialStep].boxSize} bg-transparent border-4 border-blue-500`}>
      <div className="bg-white px-5 py-2 rounded-lg shadow-lg absolute bottom-[260px] left-[7rem] flex items-center gap-4">
        <div>
          <p className="text-[20px] font-semibold">{tutorialContent[tutorialStep].title}</p>
          <p className="text-[14px] font-normal">{tutorialContent[tutorialStep].description}</p>
        </div>
      </div>

      <img 
        src="/direction.png" 
        alt="Tutorial arrow" 
        className={`w-13 h-3 z-50 ${tutorialContent[tutorialStep].arrowPosition} ${tutorialContent[tutorialStep].arrowRotation} transition-all duration-300`}
      />
      
      <div className={`flex gap-14 ${tutorialContent[tutorialStep].buttonPosition}`}>
        <button 
          className="px-3 py-1 text-[20px] font-medium hover:bg-slate-200 bg-white text-updated-color-blue rounded-[25px] z-50"
          onClick={() => setShowTutorial(false)}
        >
          Skip
        </button>
        <button 
          className="px-3 py-1 text-[20px] font-medium hover:bg-slate-200 bg-white text-updated-color-blue rounded-[25px]"
          onClick={handleNextTutorial}
        >
          {tutorialStep === Object.keys(tutorialContent).length ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  </div>
)}

*/}
    </div>
  );
};

export default Transcribe;
