// import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';

const LearnMoreContainer = () => {
  return (
    <div className='bg-gradient-to-br from-[#007EF3] to-[#7977D1] text-white font-montserrat'>
      <div className='max-w-[1512px] w-full mx-auto px-10 md:pl-32 sm:p-16 py-9 flex flex-col gap-6 sm:gap-x-36 sm:gap-10 flex-wrap sm:flex-row'>
        <div className='text-sm w-fit'>
          <p className='text-xl font-semibold'>About</p>
          <p className='opacity-60 font-semibold'>
            BlueJay AI Technologies, Inc
          </p>
          {/* <p className='opacity-60'>interpret App</p> */}
          <p className='opacity-60 font-semibold'>support@interpretapp.ai</p>
        </div>
        <div className='text-sm w-fit'>
          <p className='text-xl font-semibold'>Privacy and Terms</p>
          <div className='flex flex-col gap-4 font-semibold'>
            <Link className='opacity-60 text-white no-underline' to='/tos'>
              Terms of Service
            </Link>
            <Link className='opacity-60 text-white no-underline' to='/privacy'>
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className='text-sm w-fit'>
          <p className='text-xl font-semibold'>Social</p>
          {/* <p>Subscribe to our newsletter</p>
				<div className='flex'>
					<input
						style={{
							borderRadius: 20,
							fontSize: 16,
						}}
						className='placeholder:text-not-black/60 bg-white/10 h-11 pl-2'
						type='email'
						placeholder='Email'
						onChange={(e) => {}}
						onKeyDown={(e) => {}}
					/>
					<button className='-ml-6'>
						<ArrowRightOutlined />
					</button>
				</div> */}
          <a
            href='https://www.linkedin.com/company/interpretapp/'
            className='text-white opacity-60'
          >
            <img src='/linkedin.svg' alt='linkedin' className='w-7 h-7' />
          </a>
          <a
            href='https://discord.gg/wqCfM6mjPS'
            className='text-white opacity-60'
          >
            <img src='/discord.svg' alt='discord' className='w-7 h-7 ml-8' />
          </a>
          <a
            href='https://x.com/InterpretAppX'
            className='text-white opacity-60'
          >
            <img src='/x.svg' alt='x.com' className='w-6 h-6 ml-8' />
          </a>
          <a
            href='https://www.instagram.com/interpretapp.ai/'
            className='text-white opacity-60'
          >
            <img src='/ig.svg' alt='instagram' className='w-6 h-6 ml-8' />
          </a>
        </div>
        <p className='text-xs basis-full w-fit'>
          © Copyright {new Date().getFullYear()} Bluejay Ai Technologies Inc.
        </p>
      </div>
    </div>
  );
};

export default LearnMoreContainer;
