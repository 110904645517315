import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import ReactMarkdown from 'react-markdown';
import { top100WorldLanguages } from '../../languages';

const ChatbotWidget = React.forwardRef(
  (
    {
      className = '',
      onClose,
      contextRef,
      isChatbotOpenRef,
      roomId,
      roomPassword,
      isToggled,
    },
    ref,
  ) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null);

    // State for the Live Outline feature
    const [selectedTab, setSelectedTab] = useState('AI Chat'); // Default tab
    const [outlineContent, setOutlineContent] = useState('');
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('Select Language'); // Default language
    const selectedLanguageRef = useRef(selectedLanguage);
    const [languageSearch, setLanguageSearch] = useState('');
    const outlineContentRef = useRef('');
    const lastContextRefSnapshotRef = useRef('');
    const lastContextRefSnapshotSuggestedPromptsRef = useRef('');
    const languageDropdownRef = useRef(null);

    // State for suggested prompts
    const [suggestedPrompts, setSuggestedPrompts] = useState([
      'Generate a summary',
      'Generate an outline',
    ]);

    React.useImperativeHandle(ref, () => ({
      updateOutline,
      getOutlineContent: () => outlineContent,
    }));

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    useEffect(() => {
      outlineContentRef.current = outlineContent;
    }, [outlineContent]);

    useEffect(() => {
      selectedLanguageRef.current = selectedLanguage;
    }, [selectedLanguage]);

    // Generate initial assistant message
    useEffect(() => {
      setMessages([
        {
          role: 'assistant',
          content:
            'Hello, I am Eva AI, your personal AI chat assistant for Interpret AI. You can ask me questions about the meeting. You can also go to Live Outline to see a live outline of the meeting.',
        },
      ]);
    }, []);

    // Close the window when clicking outside of the rectangle
    useEffect(() => {
      const handleClickOutside = (event) => {
        const target = event.target;
        if (
          languageDropdownRef.current &&
          !languageDropdownRef.current.contains(target)
        ) {
          setLanguageDropdownOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const translateMessages = async (
      messages,
      setMessages,
      outlineContent,
      language,
    ) => {
      try {
        // Extract messages that need to be translated
        const textsToTranslate = messages
          .filter((message) => message.role !== 'user')
          .map((message) => message.content);

        // Translate messages
        const translatedTexts = await translateTexts(
          textsToTranslate,
          language,
        );

        // Update the messages array with translated content
        const updatedMessages = messages.map((message) => {
          if (message.role !== 'user') {
            return { ...message, content: translatedTexts.shift() };
          }
          return message;
        });

        setMessages(updatedMessages);

        // Translate outline content if it exists
        if (outlineContent) {
          const [translatedOutline] = await translateTexts(
            [outlineContent],
            language,
          );
          setOutlineContent(translatedOutline);
        }

        if (suggestedPrompts.length) {
          const translatedPrompts = await translateTexts(
            suggestedPrompts,
            language,
          );
          setSuggestedPrompts(translatedPrompts);
        }
      } catch (error) {
        console.error('Error translating messages:', error);
      }
    };

    // Function to handle sending messages
    const handleSend = async (messageContent) => {
      const content = messageContent || input.trim();
      if (!content) return;

      const messagesCopy = JSON.parse(JSON.stringify(messages));

      // Add user message
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'user', content },
      ]);

      messagesCopy.push({ role: 'user', content });

      if (contextRef.current) {
        messagesCopy.unshift({
          role: 'assistant',
          content: 'Transcript context: ' + contextRef.current,
        });
      }

      if (selectedLanguage == 'Select Language') {
        messagesCopy.unshift({
          role: 'system',
          content: `You are Eva AI, an AI assistant for Interpret AI. Unless necessary, try to give more concise answers.`,
        });
      } else {
        messagesCopy.unshift({
          role: 'system',
          content: `You are Eva AI, an AI assistant for Interpret AI. Unless necessary, try to give more concise answers. Respond in ${selectedLanguage}.`,
        });
      }

      setInput('');

      try {
        const response = await axios.post(
          `${serverURL}/call_chatbot`,
          {
            messages: messagesCopy,
            chatbot_request_type: 'chat',
            room_id: roomId,
            room_password: roomPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
              'Content-Type': 'application/json',
            },
            responseType: 'json',
          },
        );

        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: response.data },
        ]);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };

    useEffect(() => {
      let interval;
      if (isToggled) {
        updateOutline(); // Update outline immediately
        interval = setInterval(updateOutline, 30000); // Schedule updates every 30 seconds
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [isToggled]);

    // Function to update the Live Outline
    const updateOutline = async () => {
      if (!contextRef.current) return; // Exit early if toggle is off

      const currentContext = contextRef.current || '';
      const lastSnapshot = lastContextRefSnapshotRef.current || '';

      if (currentContext === lastSnapshot) return;

      const newMessages = currentContext.slice(lastSnapshot.length).trim();

      if (!newMessages) return;

      const messagesToSend = [
        {
          role: 'system',
          content: `Output bullet points in markdown, starting each line with a '-' for summaries.`,
        },
        { role: 'user', content: newMessages },
      ];

      lastContextRefSnapshotRef.current = currentContext;

      try {
        const response = await axios.post(
          `${serverURL}/call_chatbot`,
          {
            messages: messagesToSend,
            chatbot_request_type: 'outline',
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
              'Content-Type': 'application/json',
            },
          },
        );

        const newOutline = response.data.trim();

        if (newOutline) {
          setOutlineContent((prev) => `${prev}\n${newOutline}`);
          outlineContentRef.current = `${outlineContentRef.current}\n${newOutline}`;
        }
      } catch (error) {
        console.error('Error updating outline:', error);
      }
    };

    // Function to update suggested prompts
    const updateSuggestedPrompts = async () => {
      if (!isChatbotOpenRef) return;
      const currentContext = contextRef.current || '';
      if (!currentContext.trim()) return;

      const lastSnapshot =
        lastContextRefSnapshotSuggestedPromptsRef.current || '';

      if (currentContext == lastSnapshot) return;

      // Get new messages since the last snapshot
      const newMessages =
        lastSnapshot.length < 10
          ? currentContext.slice(0)
          : currentContext.slice(lastSnapshot.length - 10).trim();

      const messagesToSend = [];
      if (selectedLanguage !== 'Select Language') {
        messagesToSend.push({
          role: 'system',
          content: `You are an assistant suggesting questions a user might ask about the meeting transcript provided. For example, what does DFS mean? Or, what are the action items? Just output the suggestions split by newline. Don't interact with the user. Output in ${selectedLanguageRef.current}`,
        });
      } else {
        messagesToSend.push({
          role: 'system',
          content: `You are an assistant suggesting questions a user might ask about the meeting transcript provided. For example, what does DFS mean? Or, what are the action items? Just output the suggestions split by newline. Don't interact with the user.`,
        });
      }
      messagesToSend.push({
        role: 'user',
        content: `Based on this transcript, suggest two questions:\n${newMessages}`,
      });

      lastContextRefSnapshotSuggestedPromptsRef.current = currentContext;

      try {
        const response = await axios.post(
          `${serverURL}/call_chatbot`,
          {
            messages: messagesToSend,
            chatbot_request_type: 'generate_suggestions',
            room_id: roomId,
            room_password: roomPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
              'Content-Type': 'application/json',
            },
            responseType: 'json',
          },
        );

        const suggestions = response.data
          .trim()
          .split('\n')
          .map((line) => line.replace(/^\d+\.\s*/, '').trim())
          .filter((s) => s);
        setSuggestedPrompts([...suggestedPrompts.slice(0, 2), ...suggestions]);
      } catch (error) {
        console.error('Error updating suggested prompts:', error);
      }
    };

    // useEffect to handle the Live Outline updates every 30 seconds
    useEffect(() => {
      let interval;
      if (selectedTab === 'Live Outline' || isToggled) {
        updateOutline();
        interval = setInterval(updateOutline, 30000); // Update outline every 30 seconds
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [selectedTab, isToggled, isChatbotOpenRef.current]);

    // useEffect to update suggested prompts every 30 seconds
    useEffect(() => {
      let interval;
      const checkAndUpdatePrompts = async () => {
        console.log('checking and updating prompts', contextRef.current);
        while (!contextRef.current || contextRef.current.trim() === '') {
          console.log(
            'looping checking and updating prompts',
            contextRef.current,
          );
          await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 5 seconds
        }
        await updateSuggestedPrompts();
      };

      if (selectedTab === 'AI Chat') {
        checkAndUpdatePrompts();
        interval = setInterval(checkAndUpdatePrompts, 30000);
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [selectedTab]);

    const filteredLanguages = [
      'Select Language',
      ...top100WorldLanguages.filter((lang) =>
        lang.toLowerCase().includes(languageSearch.toLowerCase()),
      ),
    ];

    // SpeechBubble component
    const SpeechBubble = ({ text, onClick }) => (
      <div
        className='bg-blue-500 text-white py-1 px-2 rounded cursor-pointer text-center m-1 max-w-xs break-words text-sm'
        onClick={onClick}
      >
        {text}
      </div>
    );

    return (
      <div
        className={`fixed bottom-4 h-[90%] z-20 right-4 w-[500px] bg-white rounded-xl shadow-lg flex flex-col overflow-hidden ${className}`}
      >
        {/* Header */}
        <div className='bg-gradient-to-r from-updated-color-blue to-updated-color-purple p-4 flex items-center justify-between'>
          <div className='flex items-center'>
            <div className='ml-2'>
              <div className='text-white font-semibold'>Eva AI</div>
              <div className='text-white text-xs'>AI Assistant</div>
            </div>
          </div>
          <div className='flex space-x-4 items-center'>
            <button
              onClick={() => setSelectedTab('AI Chat')}
              className={`px-3 py-1 rounded ${
                selectedTab !== 'AI Chat'
                  ? 'bg-white text-updated-color-blue'
                  : 'bg-gray-300 text-white active:bg-gray-400 shadow-md transform active:translate-y-0.5 active:shadow-sm'
              } cursor-pointer`}
            >
              AI Chat
            </button>
            <button
              onClick={() => setSelectedTab('Live Outline')}
              className={`px-3 py-1 rounded ${
                selectedTab !== 'Live Outline'
                  ? 'bg-white text-updated-color-blue'
                  : 'bg-gray-300 text-white active:bg-gray-400 shadow-md transform active:translate-y-0.5 active:shadow-sm'
              } cursor-pointer`}
            >
              Live Outline
            </button>
            <div className='relative' ref={languageDropdownRef}>
              <button
                onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
                className='px-3 py-1 rounded bg-white text-updated-color-blue'
              >
                {selectedLanguage}
              </button>
              {languageDropdownOpen && (
                <div className='absolute bg-white border rounded shadow max-h-60 w-[300px] overflow-auto mt-2 p-2 right-0'>
                  <input
                    type='text'
                    placeholder='Search language'
                    value={languageSearch}
                    onChange={(e) => setLanguageSearch(e.target.value)}
                    className='p-1 mb-2 w-full border rounded text-sm'
                  />
                  <div className='grid grid-cols-3 gap-2'>
                    {filteredLanguages.map((lang, index) => (
                      <div
                        key={index}
                        className='px-2 py-1 text-center cursor-pointer border rounded hover:bg-gray-200'
                        onClick={() => {
                          setSelectedLanguage(lang);
                          setLanguageDropdownOpen(false);
                          setLanguageSearch('');
                          translateMessages(
                            messages,
                            setMessages,
                            outlineContent,
                            lang,
                          );
                        }}
                      >
                        {lang}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <button onClick={onClose}>
              <img
                src='/close-icon.svg'
                alt='Close'
                className='w-4 h-4 filter invert'
              />
            </button>
          </div>
        </div>

        {/* Content */}
        {selectedTab === 'Live Outline' ? (
          // Live Outline Content
          <div className='flex-1 p-4 overflow-y-auto bg-gray-100'>
            <ReactMarkdown>{outlineContent}</ReactMarkdown>
            <div style={{ color: 'gray', fontFamily: 'Arial, sans-serif' }}>
              Generating Live Outline...
            </div>
          </div>
        ) : (
          // Messages and Speech Bubbles
          <div className='flex-1 p-4 overflow-y-auto bg-gray-100'>
            {/* Messages */}
            <div className='space-y-4'>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex items-start ${message.role === 'user' ? 'justify-end' : ''}`}
                >
                  {message.role === 'assistant' && (
                    <img
                      src='/favicon.ico'
                      alt='Chatbot'
                      className='w-6 h-6 rounded-full'
                    />
                  )}
                  <div
                    className={`${
                      message.role === 'assistant'
                        ? 'ml-2 bg-gradient-to-r from-updated-color-blue to-updated-color-purple text-white'
                        : 'mr-2 bg-white text-updated-color-new-black'
                    } py-1 px-2 rounded-lg shadow text-base`} // Explicitly set text size to be reasonable
                  >
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef}></div>
            </div>
          </div>
        )}

        {selectedTab === 'AI Chat' && (
          <>
            <div className='p-2 flex items-center border-t'>
              <input
                type='text'
                placeholder='Write your message'
                className='flex-1 p-2 border rounded-full text-sm focus:outline-none focus:ring focus:border-updated-color-blue'
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSend()}
              />
              <button className='ml-2' onClick={() => handleSend()}>
                <img src='/send-icon.svg' alt='Send' className='w-6 h-6' />
              </button>
            </div>
            <div className='flex flex-wrap justify-center items-center space-x-2 mb-4'>
              {suggestedPrompts.filter(Boolean).map((prompt, index) => (
                <SpeechBubble
                  key={index}
                  text={prompt}
                  onClick={() => handleSend(prompt)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  },
);

ChatbotWidget.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  contextRef: PropTypes.object.isRequired,
  isChatbotOpenRef: PropTypes.object.isRequired,
};

export default ChatbotWidget;
