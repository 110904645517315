import { useState } from 'react';

export const FolderPopup = ({ onClose, onSubmit }) => {
  const [folderName, setFolderName] = useState('Untitled folder');

  const onChange = (e) => {
    setFolderName(e.target.value);
  };

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-blue-300 bg-opacity-30'>
      <div className='bg-white w-2/6 h-2/6 p-8 gap-8 flex flex-col rounded-lg shadow-sm'>
        <p className='text-4xl'>Create a new folder</p>
        <input
          className='w-full h-12 pl-4 text-xl border-dodgerblue-100 border-solid border-2 rounded-lg'
          value={folderName}
          onChange={onChange}
        />
        <div className='self-end flex gap-8'>
          <button onClick={onClose} className='text-xl text-blue'>
            Cancel
          </button>
          <button
            onClick={() => onSubmit(folderName)}
            className='text-xl text-blue'
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
