import { useState } from 'react';

export const RenameFilePopup = ({ name, onClose, onSubmit }) => {
  const [fileName, setFileName] = useState(name);

  const onChange = (e) => {
    setFileName(e.target.value);
  };

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-blue-300 bg-opacity-30'>
      <div className='bg-white text-not-black w-2/6 h-fit p-8 gap-8 flex flex-col rounded-lg shadow-sm font-montserrat'>
        <p className='text-xl font-medium'>Rename {name}</p>
        <input
          className='w-[95%] h-12 pl-4 text-xl border-dodgerblue-300 border-solid border-2 rounded-lg font-montserrat'
          value={fileName}
          onChange={onChange}
        />
        <div className='w-full flex gap-8 justify-between'>
          <button
            onClick={onClose}
            className='text-xl text-dodgerblue-100 font-montserrat'
          >
            Cancel
          </button>
          <button
            onClick={() => onSubmit(fileName)}
            className='text-xl text-dodgerblue-100 font-montserrat'
          >
            Rename
          </button>
        </div>
      </div>
    </div>
  );
};
