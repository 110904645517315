import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { serverURL } from './httpContext';
import axios from 'axios';
import { jwtTokenRef } from './httpContext';
import { CaretDownFilled } from '@ant-design/icons';
import ImageTranslateLanguageSelectOverlay from './components/mobile/ImageTranslateLanguageSelectOverlay';

const NavBarMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('home');
  const [toggle, setToggle] = useState(false);
  const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const status = useRef(null);
  const [isSaved, setIsSaved] = useState(false);
  const outputLanguageRef = useRef(null);
  const fileToTranslate = useRef(null);
  const [animateClass, setAnimateClass] = useState('');
  const [animImgSrc, setAnimImgSrc] = useState('/general--category.svg');
  const fileToSave = useRef(null);

  const handlePhotoUpload = (event) => {
    fileToTranslate.current = event.target.files[0];
    setShowLanguageOverlay(true);
  };

  const handleTranslatePhoto = async (outputLanguage) => {
    if (fileToTranslate.current && outputLanguage) {
      console.log('Translating photo');
      try {
        // Compress the selected image
        const compressedImage = await imageCompression(
          fileToTranslate.current,
          {
            maxSizeMB: 10, // Adjust the maximum size as needed
            maxWidthOrHeight: 1000, // Adjust the maximum width or height as needed
          },
        );

        setShowLanguageOverlay(false);
        setIsTranslating(true);
        const compressedImageUrl = URL.createObjectURL(compressedImage);
        setImageSrc(compressedImageUrl);

        const formData = new FormData();
        formData.append('file', compressedImage, fileToTranslate.current.name);
        status.current = 'translating';
        const response = await axios.post(
          `${serverURL}/translate_image_async?output_language=${outputLanguage}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'json', // Ensure the response is a blob
          },
        );

        var responseJson = await response.data;
        const file_name = responseJson.file_name;

        const getTranslatedImage = async (retryCount = 0, maxRetries = 200) => {
          const fetchData = async (currentRetryCount) => {
            try {
              const response = await axios.get(
                `${serverURL}/get_translated_image?file_name=${file_name}`,
                {
                  headers: {
                    Authorization: `Bearer ${jwtTokenRef.current}`,
                  },
                  responseType: 'blob', // Ensure the response is a blob
                },
              );

              if (response.status === 200) {
                const responseData = response.data;
                return responseData;
              } else {
                throw new Error(`Received status code ${response.status}`);
              }
            } catch (error) {
              if (currentRetryCount < maxRetries) {
                console.log('Retrying...', currentRetryCount);
                await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
                return fetchData(currentRetryCount + 1);
              } else {
                console.error('Max retries exceeded or other error:', error);
                throw error;
              }
            }
          };
          const fileData = await fetchData(retryCount);
          return { fileData };
        };

        const { fileData: fileResponse } = await getTranslatedImage();

        // Create a URL from the blob
        const url = URL.createObjectURL(fileResponse);
        setImageSrc(url);
        setIsTranslating(false);
        status.current = 'transcribing';
        try {
          const formData = new FormData();
          formData.append(
            'file',
            compressedImage,
            fileToTranslate.current.name,
          );
          formData.append('output_language', outputLanguageRef.current);
          formData.append('file_name', file_name);
          console.log('Transcribing image...');

          const transcribeResponse = await axios.post(
            `${serverURL}/transcribe_image_async`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${jwtTokenRef.current}`,
                'Content-Type': 'multipart/form-data',
              },
              responseType: 'json', // Ensure the response is JSON
            },
          );

          if (transcribeResponse.status === 200) {
            console.log('Transcription successful:', transcribeResponse.data);
          } else {
            console.error(
              'Transcription failed with status:',
              transcribeResponse.status,
            );
            throw new Error(
              `Transcription failed with status: ${transcribeResponse.status}`,
            );
          }
        } catch (error) {
          console.error('Error during transcription:', error);
          throw error;
        }

        const getTranscribedImage = async (
          retryCount = 0,
          maxRetries = 200,
        ) => {
          let result = {
            title: '',
            description: '',
            transcript: '',
          };
          const fetchData = async (currentRetryCount) => {
            try {
              const response = await axios.get(
                `${serverURL}/get_transcribed_image`,
                {
                  headers: {
                    Authorization: `Bearer ${jwtTokenRef.current}`,
                  },
                  params: {
                    file_name: file_name,
                  },
                },
              );

              if (response.status === 200) {
                if (
                  'is_transcribed' in response.data &&
                  !response.data.is_transcribed
                ) {
                  console.log(
                    'Not transcribed, retrying... Attempt:',
                    currentRetryCount + 1,
                  );
                  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
                  return fetchData(currentRetryCount + 1);
                } else {
                  result = {
                    title: response.data.title,
                    description: response.data.description,
                    transcript: response.data.transcript,
                  };
                  return result;
                }
              } else {
                if (currentRetryCount < maxRetries) {
                  console.log('Retrying... Attempt:', currentRetryCount + 1);
                  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
                  return fetchData(currentRetryCount + 1);
                } else {
                  throw new Error('Max retries exceeded.');
                }
              }
            } catch (error) {
              console.error('Error fetching transcribed image:', error);
              if (currentRetryCount < maxRetries) {
                console.log(
                  'Retrying after error... Attempt:',
                  currentRetryCount + 1,
                );
                await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
                return fetchData(currentRetryCount + 1);
              } else {
                throw error;
              }
            }
          };
          const transcribedData = await fetchData(retryCount);
          return transcribedData;
        };

        const transcribedData = await getTranscribedImage();
        fileToSave.current = transcribedData;
        status.current = 'transcribed';
        // Update the user info with the new profile picture URL
      } catch (error) {
        console.log('Error updating profile picture:', error);
        // setErrorMessage("Failed to update profile picture. Please try again.");
      }
    } else {
      console.log('No file selected');
    }
  };

  useEffect(() => {
    if (location.pathname === '/docs') {
      setSelectedTab('docs');
    } else {
      setSelectedTab('home');
    }
  }, [location]);

  const handleToggleClick = () => {
    if (!toggle) {
      setAnimImgSrc('/add@2x.png');
      setAnimateClass('animate-orbit-back');
    } else {
      setAnimImgSrc('/general--category.svg');
      setAnimateClass('animate-orbit');
    }
    setToggle(!toggle);
  };

  const TranslatedPhotoComponent = ({ imageSrc, onClose, onSave }) => {
    const [isOverlapping, setIsOverlapping] = useState(false);
    const retakeInputRef = useRef(null);

    const handleButtonClick = () => {
      retakeInputRef.current.click();
    };

    useEffect(() => {
      const checkOverlap = () => {
        const saveButton = document.querySelector('.save-button');
        const messageDiv = document.querySelector('.message-div');
        const image = document.querySelector('.translated-image');

        if (saveButton && messageDiv && image) {
          const saveButtonRect = saveButton.getBoundingClientRect();
          const messageDivRect = messageDiv.getBoundingClientRect();
          const imageRect = image.getBoundingClientRect();

          const isOverlappingSave =
            saveButtonRect.top < imageRect.bottom &&
            saveButtonRect.bottom > imageRect.top &&
            saveButtonRect.left < imageRect.right &&
            saveButtonRect.right > imageRect.left;

          const isOverlappingMessage =
            messageDivRect.top < imageRect.bottom &&
            messageDivRect.bottom > imageRect.top &&
            messageDivRect.left < imageRect.right &&
            messageDivRect.right > imageRect.left;

          setIsOverlapping(isOverlappingSave || isOverlappingMessage);
        }
      };

      window.addEventListener('resize', checkOverlap);
      checkOverlap();

      return () => {
        window.removeEventListener('resize', checkOverlap);
      };
    }, []);

    return (
      <div className='fixed inset-0 z-40 bg-black'>
        <div className='w-full h-full flex flex-col'>
          <div className='w-full h-1/2 flex items-end bg-black pl-5 pb-4'>
            <div className='flex w-4/5 h-fit items-center text-white'>
              <img
                className='w-8 h-8'
                alt=''
                src='/interface--close.svg'
                onClick={() => {
                  onClose();
                  setIsSaved(false);
                  setIsTranscribing(false);
                  setIsTranslating(false);
                  setIsOverlapping(false);
                  status.current = null;
                }}
              />
              <div
                className='mx-auto flex justify-center items-center gap-1 text-base py-1 pl-6'
                onClick={() => setShowLanguageOverlay(true)}
              >
                <div className='text-center flex flex-col gap-1'>
                  <div>Output Language</div>
                  <div className='flex gap-1 justify-center items-center'>
                    {outputLanguageRef.current}
                    <CaretDownFilled />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full h-full overflow-y-scroll relative'>
            {isTranslating && (
              <svg
                className='fixed z-50 animate-spin h-10 w-10 left-0 right-0 m-auto top-0 bottom-0 text-updated-color-blue'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-75'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className=''
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            )}
            <img
              src={imageSrc}
              alt='Uploaded'
              className='w-full h-auto object-contain translated-image'
            />
          </div>
          <div className='flex pt-5 w-full h-1/2 bg-black'>
            <div className='w-4/5 flex items-center h-fit text-white'>
              <input
                type='file'
                accept='image/*'
                ref={retakeInputRef}
                style={{ display: 'none' }}
                onChange={(e) => {
                  setIsSaved(false);
                  handlePhotoUpload(e);
                }}
              />
              <button
                onClick={handleButtonClick}
                className='text-base font-semibold py-2.5 px-5 flex gap-2 text-white flex-col items-center'
              >
                <img src='/retake.svg' alt='' />
                Retake
              </button>
              <div
                onClick={() => {
                  !isTranslating && !isTranscribing && !isSaved
                    ? onSave()
                    : undefined;
                }}
                className={`save-button font-semibold py-2.5 px-5 text-base mx-auto ${
                  isTranslating || isTranscribing || isSaved
                    ? ''
                    : 'border-2 border-solid border-white'
                } rounded-full cursor-pointer ${isOverlapping ? 'bg-gray-700 bg-opacity-75' : ''}`}
              >
                {isTranslating
                  ? 'Translating...'
                  : isTranscribing
                    ? 'Saving to Documents...'
                    : isSaved
                      ? 'Saved to Documents'
                      : 'Save'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PhotoUploadComponent = () => {
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    return (
      <div className='flex flex-col items-center justify-center'>
        <input
          type='file'
          accept='image/*'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handlePhotoUpload}
        />
        <button onClick={handleButtonClick}>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect y='0' width='48' height='48' rx='24' fill='white' />
            <rect y='0' width='48' height='48' rx='24' fill='#75BAF8' />
            <g clipPath='url(#clip0_2427_26951)'>
              <path
                d='M13.875 17.125H34.125V30.625C34.125 31.8676 33.118 32.875 31.875 32.875H16.125C14.882 32.875 13.875 31.8676 13.875 30.625V17.125Z'
                stroke='white'
                strokeWidth='2.8125'
                strokeLinejoin='round'
              />
              <circle
                cx='24'
                cy='25'
                r='3.375'
                stroke='white'
                strokeWidth='2.8125'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M29.625 13.75L31.875 13.75'
                stroke='white'
                strokeWidth='2.8125'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_2427_26951'>
                <rect
                  width='27'
                  height='27'
                  fill='white'
                  transform='translate(10 11)'
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        {toggle && <div className='font-bold text-[#75BAF8]'>Camera</div>}
      </div>
    );
  };

  const TextTranslateComponent = () => {
    return (
      <div className='flex flex-col items-center justify-center'>
        <button onClick={() => navigate('/text_translate')}>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='48' height='48' rx='24' fill='white' />
            <rect width='48' height='48' rx='24' fill='#75BAF8' />
            <path
              d='M24.8375 28.3375L21.6625 25.2L21.7 25.1625C23.8177 22.8089 25.3999 20.0241 26.3375 17H30V14.5H21.25V12H18.75V14.5H10V17H23.9625C23.125 19.4 21.8 21.6875 20 23.6875C18.8375 22.4 17.875 20.9875 17.1125 19.5H14.6125C15.525 21.5375 16.775 23.4625 18.3375 25.2L11.975 31.475L13.75 33.25L20 27L23.8875 30.8875L24.8375 28.3375ZM31.875 22H29.375L23.75 37H26.25L27.65 33.25H33.5875L35 37H37.5L31.875 22ZM28.6 30.75L30.625 25.3375L32.65 30.75H28.6Z'
              fill='white'
            />
          </svg>
        </button>
        {toggle && <div className='font-bold text-[#75BAF8]'>Translate</div>}
      </div>
    );
  };

  return (
    <>
      <div className='flex relative flex-col fixed bottom-0 left-0 text-xs text-dodgerblue-200 z-10'>
        <div className='flex justify-center items-center h-full w-full relative'>
          <div
            className={`rounded-full z-20 ${toggle ? 'bg-white mb-2' : 'bg-dodgerblue-200'} flex items-center justify-center transition-all duration-300 absolute bottom-8`}
            onClick={handleToggleClick}
          >
            <img
              className={`p-3 ${toggle ? 'w-8 h-8' : 'w-6 h-6'} object-contain`}
              alt=''
              src={animImgSrc}
            />
          </div>
          <div
            className={`absolute transition-all duration-500 ${animateClass} ${!toggle ? '-bottom-2' : 'bottom-10'} mx-auto left-0 right-0 flex w-fit h-fit gap-10`}
          >
            <TextTranslateComponent />
            <PhotoUploadComponent />
          </div>
        </div>
        <div className='absolute bottom-0 w-full h-16'>
          <div className='svg-container'>
            <svg
              viewBox='0 0 393 92'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 0H98.25H138.126C143.994 0 149.565 2.57645 153.365 7.04703L169.935 26.5418C185.485 44.8358 214.071 43.7039 228.126 24.2375L239.638 8.29257C243.398 3.08493 249.43 0 255.853 0H294.75H393V68C393 81.2548 382.255 92 369 92H24C10.7452 92 0 81.2548 0 68V0Z'
                fill='white'
              />
            </svg>
          </div>
        </div>
        <div className='flex items-end justify-center absolute bottom-0 w-full py-0'>
          <div
            className={`flex-1 flex flex-col items-center justify-start gap-[6px] ${
              selectedTab === 'home' ? 'text-dodgerblue-200' : 'text-[#A2A2A2]'
            }`}
            onClick={() => {
              setSelectedTab('home');
              setAnimateClass('');
              setToggle(false);
              setAnimImgSrc('/general--category.svg');
              if (location.pathname === '/docs') {
                navigate('/');
              }
            }}
          >
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.0819 8.01002L14.5319 2.77002C13.2519 1.75002 11.2519 1.74002 9.98192 2.76002L3.43192 8.01002C2.49192 8.76002 1.92192 10.26 2.12192 11.44L3.38192 18.98C3.67192 20.67 5.24192 22 6.95192 22H17.5519C19.2419 22 20.8419 20.64 21.1319 18.97L22.3919 11.43C22.5719 10.26 22.0019 8.76002 21.0819 8.01002ZM13.0019 18C13.0019 18.41 12.6619 18.75 12.2519 18.75C11.8419 18.75 11.5019 18.41 11.5019 18V15C11.5019 14.59 11.8419 14.25 12.2519 14.25C12.6619 14.25 13.0019 14.59 13.0019 15V18Z'
                fill={selectedTab === 'home' ? '#007EF3' : '#fff'}
                stroke={selectedTab === 'home' ? '#fff' : '#A2A2A2'}
                strokeWidth={selectedTab === 'home' ? '.25' : '2.5'}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <div
              className={`tracking-[0.02em] leading-[125%] ${selectedTab === 'home' && 'font-medium'}`}
            >
              Home
            </div>
          </div>
          <div
            className={`flex-1 flex flex-col items-center justify-start gap-[6px] ${
              selectedTab === 'docs' ? 'text-dodgerblue-200' : 'text-[#A2A2A2]'
            }`}
            onClick={() => {
              setSelectedTab('docs');
              setAnimateClass('');
              setToggle(false);
              setAnimImgSrc('/general--category.svg');
              if (location.pathname !== '/docs') {
                navigate('/docs');
              }
            }}
          >
            <svg
              width='28'
              height='27'
              viewBox='0 0 28 27'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_2810_24990)'>
                <path
                  d='M4.125 19.6248V6.12476H12L14.25 8.37476H24.375V19.6248C24.375 20.8674 23.3676 21.8748 22.125 21.8748H6.375C5.13236 21.8748 4.125 20.8674 4.125 19.6248Z'
                  fill={selectedTab === 'docs' ? '#007EF3' : '#FFF'}
                  stroke={selectedTab === 'docs' ? '#007EF3' : '#A2A2A2'}
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_2810_24990'>
                  <rect
                    width='27'
                    height='27'
                    fill='white'
                    transform='translate(0.25)'
                  />
                </clipPath>
              </defs>
            </svg>
            <div
              className={`tracking-[0.02em] leading-[125%] ${selectedTab === 'docs' && 'font-medium'}`}
            >
              Docs
            </div>
          </div>
        </div>
      </div>
      {imageSrc && (
        <TranslatedPhotoComponent
          imageSrc={imageSrc}
          onClose={() => setImageSrc(null)}
          onSave={async () => {
            try {
              setIsSaved(false);
              setIsTranscribing(true);
              // wait for status to be transcribed
              while (status.current !== 'transcribed') {
                await new Promise((resolve) => setTimeout(resolve, 300));
              }
              const formData = new FormData();
              formData.append('file', fileToTranslate.current);

              const response = await axios.post(
                `${serverURL}/save_image_to_db`,
                formData,
                {
                  params: {
                    title: fileToSave.current.title,
                    description: fileToSave.current.description,
                    transcript: fileToSave.current.transcript,
                    output_language: outputLanguageRef.current,
                  },
                  headers: {
                    Authorization: `Bearer ${jwtTokenRef.current}`,
                    'Content-Type': 'multipart/form-data',
                  },
                },
              );
              await response.data;
              setIsTranscribing(false);
              setIsSaved(true);
              status.current = null;
            } catch (error) {
              console.log('Error transcribing image:', error);
            }
          }}
        />
      )}
      {showLanguageOverlay && (
        <div className='absolute top-0 left-0 w-full h-full'>
          <ImageTranslateLanguageSelectOverlay
            onClose={() => {
              console.log('onClose');
              setShowLanguageOverlay(false);
            }}
            onSelect={async (outputLanguage) => {
              console.log('onSelect');
              outputLanguageRef.current = outputLanguage;
              await handleTranslatePhoto(outputLanguage);
            }}
          />
        </div>
      )}
    </>
  );
};

export default NavBarMobile;
