import { useEffect, useState, useRef } from 'react';
import { RenameFilePopup } from './RenameFilePopup';
import { jwtTokenRef, serverURL } from '../httpContext';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { FolderOutlined } from '@ant-design/icons';

export const FolderFiles = ({ folders, recentFiles, loadMoreFiles }) => {
  const [currentFiles, setCurrentFiles] = useState(recentFiles);
  const currentFilesRef = useRef(currentFiles);
  const scrollingContainerRef = useRef(null);
  const scrollEndRef = useRef(null);
  const observer = useRef(null);
  const [openRenameFilePopup, setOpenRenameFilePopup] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState('');
  const [fileIndex, setFileIndex] = useState(-1);
  const [openMoveFilePopup, setOpenMoveFilePopup] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const lastScrollTopRef = useRef(0);
  const navigate = useNavigate();

  const handleFileRemove = async (fileToRemove) => {
    console.log('calling delete transcript ' + JSON.stringify(fileToRemove));
    setError(null); // Clear previous errors

    try {
      const response = await fetch(
        serverURL + '/delete_transcript?transcript_id=' + fileToRemove.id,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.ok) {
        console.log('File deleted successfully');
        // Update local state on success
        setCurrentFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== fileToRemove.id),
        );
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Failed to delete file';
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      setError(error.message); // Display error message
    }
  };

  const handleFileRename = async (name) => {
    setOpenRenameFilePopup(false);
    console.log('rename file ' + fileId + ' to ' + name);

    try {
      const response = await fetch(
        serverURL +
          '/rename_transcript?transcript_id=' +
          fileId +
          '&new_name=' +
          name,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.ok) {
        console.log('File renamed successfully');
        // Update the local state after a successful API call
        const newFiles = currentFiles.map((file) => {
          if (file.id === fileId) {
            return { ...file, name: name }; // Create a new object to avoid direct mutation
          }
          return file;
        });
        setCurrentFiles(newFiles);
      } else {
        console.error('Failed to rename file');
        // Handle the error: Display an error message to the user
        // Example: You might use a state variable to control an error message component
        // setError('Failed to rename the file. Please try again.');
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      // Handle the error: Display an error message to the user
      // Example:
      // setError('An error occurred while renaming the file. Please try again.');
    }
  };

  const handleFileMove = async (transcriptId, folderId) => {
    setError(null); // Clear any previous errors
    setSuccess(null); // Clear any previous success messages

    try {
      const res = await axios.post(
        `${serverURL}/add_file_to_folder?folder_id=${folderId}&file_id=${transcriptId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
          responseType: 'json',
        },
      );

      if (res.status === 200) {
        console.log('File moved successfully');
        setCurrentFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== transcriptId),
        );
        setSuccess('File moved successfully!'); // Set success message
      } else {
        const errorData = res.data; // Assuming axios response has data
        const errorMessage = errorData.message || 'Failed to move file';
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error moving file:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    currentFilesRef.current = currentFiles;
  }, [currentFiles]);

  useEffect(() => {
    setCurrentFiles(recentFiles);
  }, [recentFiles]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log('intersecting', currentFilesRef.current.length);
      }
      if (entries[0].isIntersecting && currentFilesRef.current.length > 0) {
        lastScrollTopRef.current = scrollingContainerRef.current.scrollTop;
        console.log('lastScrollTopRef.current', lastScrollTopRef.current);
        loadMoreFiles();
      }
    }, options);

    const target = scrollEndRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target);
      }
    };
  }, []);

  return (
    <div
      ref={scrollingContainerRef}
      className='h-[400px] overflow-y-auto flex flex-col text-sm text-updated-color-grey3'
    >
      {/* Error Message Display */}
      {error && (
        <div className='error-message text-red-500 p-2 rounded-md bg-red-100 border border-red-400'>
          {error}
        </div>
      )}
      {/* Success Message Display */}
      {success && (
        <div className='success-message text-green-500 p-2 rounded-md bg-green-100 border border-green-400'>
          {success}
        </div>
      )}

      {openRenameFilePopup && (
        <RenameFilePopup
          onClose={() => setOpenRenameFilePopup(false)}
          onSubmit={handleFileRename}
          name={fileName}
        />
      )}
      <div className='flex flex-col w-full py-[5px] px-0 relative gap-[10px]'>
        {currentFiles.map((file, indx) => (
          <div
            key={file.id}
            className='relative w-full cursor-pointer'
            onClick={() => {
              navigate(`/file_transcript?id=${file.id}`, {
                state: {
                  file: file,
                },
              });
            }}
          >
            <div className='rounded-3xs bg-app-close-to-white-grey-bkg grid grid-cols-4 items-center py-2.5 pl-2.5 pr-2 text-base text-updated-color-new-black hover:bg-updated-color-grey1 transition-all duration-300'>
              <div className='relative leading-[22px] font-medium pr-10'>
                {file.name}
              </div>
              <div className='leading-[22px] font-medium text-updated-color-grey3'>
                {file.transcript_type === 'gpu'
                  ? 'Audio/Video Transcript'
                  : file.transcript_type === 'image'
                    ? 'Image Transcription'
                    : ''}
              </div>
              <div className=' leading-[22px] font-medium whitespace-nowrap text-updated-color-grey3'>
                {format(parseISO(file.created_at), 'MMM dd, yyyy')}
              </div>
              <div className='flex justify-end gap-[5px] pr-10 relative'>
                <div
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFileName(file.name);
                    setFileId(file.id);
                    setOpenRenameFilePopup(true);
                  }}
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame1.svg' />
                </div>
                {/* <div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame2.svg" />
								</div>
								<div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame3.svg" />
								</div> */}
                <div
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileRemove(file);
                  }}
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame4.svg' />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMoveFilePopup(!openMoveFilePopup);
                    setFileIndex(indx);
                  }}
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame5.svg' />
                </div>
                {openMoveFilePopup && fileIndex === indx && (
                  <div
                    onMouseLeave={() => setOpenMoveFilePopup(false)}
                    className='absolute top-2 right-10 w-32 max-h-fit bg-white shadow overflow-y-auto z-10 rounded-md py-2'
                  >
                    <div className='flex flex-col gap-2'>
                      <div className='px-2 font-semibold'>Move to:</div>
                      {folders.map((folder) => {
                        if (folder.id === file.folder_id) return null;
                        return (
                          <div
                            key={folder.id}
                            className='flex items-center gap-2 p-2 hover:bg-sky-200'
                          >
                            <FolderOutlined />
                            <div
                              className=''
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileMove(file.id, folder.id, file.folder);
                                setOpenMoveFilePopup(false);
                              }}
                            >
                              {folder.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div ref={scrollEndRef}></div>
      </div>
    </div>
  );
};
