import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useState, useEffect } from 'react';

export const FeatureCarousel = ({ features }) => {
  const totalSlides = features.length;
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [visibleSlides, setVisibleSlides] = useState([]);

  useEffect(() => {
    // Calculate visible slides whenever currentSlide changes
    const prev = (currentSlide - 1 + totalSlides) % totalSlides;
    let next = (currentSlide + 1) % totalSlides;
    setVisibleSlides([prev, currentSlide - 1, next]);
  }, [currentSlide, totalSlides]);

  const getSlideOpacity = (index) => {
    return visibleSlides.includes(index) ? 'opacity-100' : 'opacity-30';
  };

  const handleNext = () => {
    console.log(currentSlide);
    if (currentSlide === totalSlides - 2) {
      setCurrentSlide(-1);
      return;
    }
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handleBack = () => {
    console.log(currentSlide);
    if (currentSlide === 0) {
      setCurrentSlide(-1);
      return;
    }
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  return (
    <div className='relative w-full border-b border-solid border-x-0 border-t-0 border-black/20'>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={80}
        totalSlides={totalSlides}
        visibleSlides={3}
        infinite={true}
        isIntrinsicHeight={true}
        dragEnabled={true}
        step={1}
        interval={3000}
        currentSlide={currentSlide}
      >
        <div className='relative overflow-hidden'>
          <div className='w-[142%] -ml-[25%]'>
            <Slider className='carousel__slider'>
              {features.map((feature, index) => (
                <Slide
                  index={index}
                  key={index}
                  className='carousel__inner-slide transition-opacity duration-500'
                  innerClassName='flex justify-center px-4'
                >
                  <div
                    className={`flex flex-col p-2 pt-0 items-center transition-all duration-300 w-full ${getSlideOpacity(
                      index,
                    )}`}
                  >
                    <img
                      src={feature.imgSrc}
                      alt={feature.title}
                      className='w-12 h-12'
                    />
                    <p className='text-center text-medium text-sm mt-2 whitespace-pre-line'>
                      {feature.title}
                    </p>
                  </div>
                </Slide>
              ))}
            </Slider>
          </div>

          <ButtonBack
            onClick={handleBack}
            className='absolute left-8 top-1/2 -translate-y-1/2 z-10 transition-colors'
          >
            <svg
              width='26'
              height='29'
              viewBox='0 0 26 29'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <ellipse
                cx='12.8766'
                cy='14.3942'
                rx='12.8766'
                ry='14.3942'
                transform='matrix(-1 0 0 1 25.7532 0)'
                fill='#C6C6C6'
                fillOpacity='1'
              />
              <path
                d='M14.2922 9L10 13.7981L14.2922 18.5961'
                stroke='#696969'
                strokeWidth='2.34967'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </ButtonBack>

          <ButtonNext
            onClick={handleNext}
            className='absolute right-8 top-1/2 -translate-y-1/2 z-10 transition-colors'
          >
            <svg
              width='26'
              height='29'
              viewBox='0 0 26 29'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <ellipse
                cx='12.8766'
                cy='14.3942'
                rx='12.8766'
                ry='14.3942'
                transform='matrix(-1 0 0 1 25.7532 0)'
                fill='#C6C6C6'
                fillOpacity='1'
              />
              <path
                d='M11.4458 9.59619L15.738 14.3942L11.4458 19.1923'
                stroke='#696969'
                strokeWidth='2.34967'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </ButtonNext>
        </div>
      </CarouselProvider>

      <style jsx='true'>{`
        :global(.carousel__slider) {
          transition: transform 0.5s ease-in-out !important;
        }
        :global(.carousel__slider-tray) {
          width: 100% !important;
          transform-origin: center center;
          transition: transform 0.5s ease-in-out !important;
        }
        :global(.carousel__inner-slide) {
          transform-origin: center center;
          transition: all 0.5s ease-in-out !important;
          margin: 0 !important;
        }
        :global(.carousel__slide) {
          padding: 0 !important;
        }
      `}</style>
    </div>
  );
};

export default FeatureCarousel;
