import { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL, jwtTokenRef } from '../../httpContext';

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${serverURL}/get-invoices`, {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        });

        // Extract invoices and sort them by date (most recent first)
        const sortedInvoices = response.data.data.sort(
          (a, b) => b.created - a.created,
        );

        setInvoices(sortedInvoices);
      } catch (err) {
        setError('Error fetching invoices. Please try again.');
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div className='relative h-screen max-w-[1512px] mx-auto text-left text-lg text-updated-color-blue font-montserrat flex flex-col'>
      <div className='flex justify-center items-center py-10'>
        <div className='rounded-xl flex flex-col items-center justify-start pt-[30px] pb-10 px-10 gap-[50px] text-base text-updated-color-new-black border-[0.5px] border-solid border-updated-color-grey2'>
          <div className='relative leading-[112.4%] font-semibold'>
            Invoices
          </div>
          {error && <div className='text-red-500 mt-2'>{error}</div>}

          {loading ? (
            // Display loading message while invoices are being fetched
            <div>Loading invoices...</div>
          ) : invoices.length > 0 ? (
            <ul className='flex flex-col items-start justify-start gap-5'>
              {invoices.map((invoice) => (
                <li
                  key={invoice.id}
                  className='flex flex-row items-center justify-between w-full border-b border-updated-color-grey1 py-2'
                >
                  <div className='flex flex-col'>
                    <span className='font-medium'>Date:</span>{' '}
                    {new Date(invoice.created * 1000).toLocaleString()}
                  </div>
                  <div className='ml-10'>
                    <a
                      href={invoice.invoice_pdf}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='rounded-8xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 text-white cursor-pointer'
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        View Invoice PDF
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>No invoices found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
