import { useState, useEffect, useRef } from 'react';

// export const serverURL = 'http://0.0.0.0:5000';
export const serverURL = 'https://interpretapp.ai:5001';

export var jwtTokenRef = undefined;

export var userInfoRef = undefined;

var setIsLoggedInStateFunc = undefined;

export function setSetIsLoggedInStateFunc(func) {
  setIsLoggedInStateFunc = func;
}

export function setJwtTokenRef(tokenRef) {
  jwtTokenRef = tokenRef;
}

export function setUserInfoRef(userRef) {
  userInfoRef = userRef;
}

{
  /*

export const checkFirstTimeLogin = async () => {
  await updateAccountInfo(jwtTokenRef, userInfoRef);
  return userInfoRef.current?.has_logged_in_before === false;
}
*/
}

export const refreshJWTToken = async (jwtTokenRef) => {
  if (jwtTokenRef === undefined) {
    return false;
  }

  if (jwtTokenRef.current === undefined) {
    return false;
  }

  console.log('Refreshing jwtToken');
  try {
    const response = await fetch(serverURL + '/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: jwtTokenRef.current,
        // Add any required data for the verification here
      }),
    });
    const json = await response.json();
    console.log(json);
    console.log('Refreshed jwtToken');
    jwtTokenRef.current = json.access_token;
    localStorage.setItem('jwtToken', jwtTokenRef.current);
    return true;
  } catch (error) {
    console.error(error);
    jwtTokenRef.current = undefined;
    return false;
  }
};

export const getJwtExpiry = (jwtToken) => {
  // Split the token into parts
  const tokenParts = jwtToken.split('.');
  const encodedPayload = tokenParts[1];

  // Decode the payload using Buffer
  const decodedPayload = atob(encodedPayload);

  // Parse the payload
  const payload = JSON.parse(decodedPayload);

  // Extract and return the expiry time
  return payload.exp;
};

export const isJwtExpired = (jwtToken) => {
  if (jwtToken === undefined) {
    return true;
  }
  const expiry = getJwtExpiry(jwtToken);
  return Date.now() >= expiry * 1000;
};

export const translateTexts = async (texts, targetLang) => {
  console.log(texts);
  console.log(targetLang);
  try {
    const response = await fetch(serverURL + '/translate_texts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtTokenRef.current,
      },

      body: JSON.stringify({
        texts: texts,
        target_language: targetLang,
      }),
    });

    if (response.status !== 200) {
      console.error('Failed to translate text');
      return [];
    }

    const json = await response.json();
    console.log(json);
    return json.translations;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updateAccountInfo = async (jwtTokenRef, userInfoRef) => {
  if (
    jwtTokenRef != undefined &&
    jwtTokenRef.current != undefined &&
    userInfoRef != undefined
  ) {
    var response = await fetch(`${serverURL}/account-info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtTokenRef.current,
      },
    });
    if (response.status === 200) {
      const response_json = await response.json();
      userInfoRef.current = response_json;
    } else {
      localStorage.removeItem('jwtToken');
      jwtTokenRef.current = undefined;
    }
  }
};
