import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { serverURL } from '../../httpContext'; // Import serverURL

const UnsubscribeEmail = () => {
  const [searchParams] = useSearchParams();
  const [verificationToken, setVerificationToken] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const verificationCode = searchParams.get('code');
    if (verificationCode) {
      setVerificationToken(verificationCode);
      handleUnsubscribe(verificationCode); // Automatically unsubscribe
    }
  }, [searchParams]);

  const handleUnsubscribe = async (verificationToken) => {
    setIsSubmitting(true);
    setMessage(''); // Clear any previous message

    try {
      const response = await axios.post(`${serverURL}/unsubscribe_email`, {
        verification_token: verificationToken,
      });

      if (response.status === 200) {
        setMessage('You have been successfully unsubscribed.');
      } else {
        setMessage(
          response.data.detail || 'An error occurred. Please try again later.',
        );
      }
    } catch (error) {
      setMessage(
        error.response?.data?.detail ||
          'An error occurred. Please try again later.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='relative max-w-[1512px] w-full mx-auto flex font-montserrat'>
      <div className='flex flex-col h-full w-full pr-16 gap-4 py-28 pl-56'>
        <h1 className='text-3xl font-semibold'>Unsubscribe from Emails</h1>
        {message && (
          <p
            className={`text-lg mt-4 ${
              message.includes('success') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </p>
        )}
      </div>
      <div className='w-full bg-updated-color-grey1 h-auto'></div>
    </div>
  );
};

export default UnsubscribeEmail;
