import { useState, useEffect, useRef } from 'react';
import RoomSelectLanguageOverlay from '../../components/mobile/RoomSelectLanguageOverlay';
import { SavePopup } from '../../components/mobile/SavePopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../socketContext';
import { playNextAudio, computeBlob } from '../../utils';
import {
  jwtTokenRef,
  serverURL,
  translateTexts,
  userInfoRef,
} from '../../httpContext';
import { CheckOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import axios from 'axios';
import RecentsSidebar from '../../components/mobile/RecentsSidebar';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';
import { Switch } from '../../components/Switch';
import { OutOfCreditsWindow } from '../../components/OutOfCreditsWindow';
import Subscription from '../../components/mobile/Subscription';
import PortalPopup from '../../components/mobile/PortalPopup';
import MobileChatbotWidget from './ChatbotWidget';

const formatDate = (date) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const Message = ({
  showOriginal,
  message,
  languageOne,
  languageTwo,
  isLoadingTTS,
  playAudio,
}) => {
  return (
    <>
      <div className='flex flex-col text-[12px]'>
        {(showOriginal ||
          message.corrected_transcripts[languageOne] ||
          message.transcript ||
          message['unconfirmed_transcript']) && (
          <div className='text-updated-color-new-black'>
            {message.timestamp_display}
          </div>
        )}
        {showOriginal && (
          <div className='relative rounded-3xs [background:linear-gradient(rgba(0,126,243,0.3),rgba(0,126,243,0.3)),rgba(255,255,255,0.2)] flex py-2 px-2 mt-2 box-border w-full'>
            <div className='inline w-fit h-fit'>
              <p
                style={{
                  fontSize: '16px',
                  color: 'black',
                  display: 'inline',
                }}
              >
                {message.corrected_transcripts[languageOne] ||
                  message.transcript}{' '}
              </p>
              <p style={{ fontSize: '16px', color: 'grey', display: 'inline' }}>
                {(message.unconfirmed_corrected_transcripts &&
                  message.unconfirmed_corrected_transcripts[languageOne]) ||
                  message.unconfirmed_transcript}
              </p>
            </div>
            {isLoadingTTS ? (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            ) : (
              <img
                src='/speaker_icon.svg'
                alt='Speaker Icon'
                className='absolute bottom-2 right-2 w-6 h-6 transition-transform transform hover:scale-105 active:scale-95'
                onClick={async () => {
                  await playAudio(
                    message.corrected_transcripts[languageOne] ||
                      message.transcript,
                  );
                }}
              />
            )}
          </div>
        )}
        {(message.corrected_transcripts[languageTwo] ||
          message.transcript ||
          message['unconfirmed_transcript']) && (
          <div className='relative rounded-3xs bg-white flex py-2 px-2 box-border w-full mt-2'>
            <div>
              <p
                style={{ fontSize: '16px', color: 'black', display: 'inline' }}
              >
                {message.corrected_transcripts[languageTwo] ||
                  message.transcript}
              </p>
              <p style={{ fontSize: '16px', color: 'grey', display: 'inline' }}>
                {(message.unconfirmed_corrected_transcripts &&
                  message.unconfirmed_corrected_transcripts[languageTwo]) ||
                  message.unconfirmed_transcript}
              </p>
            </div>
            {isLoadingTTS ? (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            ) : (
              <img
                src='/speaker_icon.svg'
                alt='Speaker Icon'
                className='absolute bottom-2 right-2 w-6 h-6 cursor-pointer transition-transform transform hover:scale-105 active:scale-95'
                onClick={async () => {
                  await playAudio(
                    message.corrected_transcripts[languageOne] ||
                      message.transcript,
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const Transcribe = () => {
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesMerged, setMessagesMerged] = useState([]);
  const messagesRef = useRef(messages);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const [languageOne, setLanguageOne] = useState('Detect Language');
  const [languageTwo, setLanguageTwo] = useState('Original');
  const languageOneRef = useRef(languageOne);
  const languageTwoRef = useRef(languageTwo);
  const [languageToSet, setLanguageToSet] = useState('');
  const [isTranscribing, setTranscribing] = useState(false);
  const isTranscribingRef = useRef(isTranscribing);
  const [showOriginal, setShowOriginal] = useState(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const stoppedTranscribing = useRef(false);
  const linkAndQRCodeRef = useRef(null);
  const audioRef = useRef(null);
  const [playingAudio, setPlayingAudio] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isLoadingTTS, setIsLoadingTTS] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [isOutOfCredits, setIsOutOfCredits] = useState(false);
  const [isSubscriptionsOpen, setIsSubscriptionsOpen] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const isChatbotOpenRef = useRef(isChatbotOpen);
  const chatbotContextRef = useRef('');
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);

  const playingAudioRef = useRef(false);
  const liveAudioEnabledRef = useRef(false);
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);
  let blankSrc =
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  const audioQueueRef = useRef([]);
  const startProcessingIndexRef = useRef(messagesRef.current.length);

  const navigate = useNavigate();

  const toggleLiveAudio = () => {
    if (!liveAudioEnabled) {
      // // Enable live audio
      audioContextRef.current.play();

      setLiveAudioEnabled(true);

      // Set the starting index to the current messages length
      let lastFinalMessage = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]['is_final']) {
          lastFinalMessage = i + 1;
          break;
        }
      }
      startProcessingIndexRef.current = lastFinalMessage;
    } else {
      // Disable live audio
      setLiveAudioEnabled(false);
      // Clear the audio queue and stop playback
      audioQueueRef.current = [];
      playingAudioRef.current = false;
      audioContextRef.current.pause();
    }
  };

  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  let tasks = [];

  useEffect(() => {
    if (!liveAudioEnabledRef.current) {
      return;
    }

    if (tasks.length > 0) {
      return;
    }

    let textToAdd = '';
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      if (message['is_final']) {
        let text = message['transcript'];
        if (
          languageTwoRef.current !== 'Original' &&
          message['corrected_transcripts'][languageTwoRef.current] !== undefined
        ) {
          text = message['corrected_transcripts'][languageTwoRef.current];
        }
        textToAdd = textToAdd + ' ' + text;
      }
    }
    if (textToAdd != '') {
      var blob = computeBlob(textToAdd, roomIdRef);
      tasks.push(blob);
    }

    let lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // wait for all the blobs to be computed
    Promise.all(tasks).then((blobs) => {
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      tasks = [];
      playNextAudio(
        playingAudioRef,
        audioQueueRef,
        audioContextRef,
        liveAudioEnabledRef,
      );
    });
  }, [messages, liveAudioEnabled]);

  const location = useLocation();
  const date = new Date();
  const formattedDate = formatDate(date);

  const startTimer = () => {
    setStartTime(new Date().getTime());
    setTimerRunning(true);
  };

  const pauseTimer = () => {
    setTimerRunning(false);
  };

  const copyMessagesToClipboard = (language) => {
    let text = '';
    for (let i = 0; i < messagesMerged.length; i++) {
      if (language == '' || language == undefined) {
        text += messagesMerged[i]['transcript'] + '\n';
      } else {
        text +=
          messagesMerged[i]['corrected_transcripts'][language] ??
          messagesMerged[i]['transcript'] + '\n';
      }
    }
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const updateMessagesMerged = (messagesUpdated) => {
    // copy the messagesUpdated array to avoid reference issues
    let messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
    let messagesMergedUpdated = [];
    let startOfMessagesToKeep = -1;
    for (let i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 4 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (let key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          if (messagesUpdatedCopy.length > 10 && startOfMessagesToKeep == -1) {
            startOfMessagesToKeep = i;
          }
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }

    if (messagesMergedUpdated.length > 0) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      lastMessageMerged['unconfirmed_transcript'] = '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
    }

    if (
      messagesUpdatedCopy.length > 0 &&
      !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
    ) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      var lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript']
          .length;
      lastMessageMerged['transcript'] = messagesMergedUpdated[
        messagesMergedUpdated.length - 1
      ]['transcript'].slice(0, -lengthOfLastMessage);
      lastMessageMerged['unconfirmed_transcript'] =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      for (var key in lastMessageMerged['corrected_transcripts']) {
        lengthOfLastMessage =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key].length;
        lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged[
          'corrected_transcripts'
        ][key].slice(0, -lengthOfLastMessage);
        lastMessageMerged['unconfirmed_corrected_transcripts'][key] =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key];
      }
    }

    // ensure only last 30 messages are stored
    if (messagesMergedUpdated.length > 15) {
      messagesMergedUpdated = messagesMergedUpdated.slice(
        messagesMergedUpdated.length - 15,
      );

      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }

    setMessagesMerged(messagesMergedUpdated);
  };
  const joinRoomAsync = async () => {
    socket.on('receive_transcriptions', async (data) => {
      let oldMessages = [...messagesRef.current];
      let message = data;

      let messageToAdd = {
        transcript_id: message['transcript_id'],
        transcript: message['transcript'],
        corrected_transcripts: message['corrected_transcripts'],
        is_final: message['is_final'],
        did_speaker_change: message['did_speaker_change'],
        timestamp: message['timestamp'],
        is_ai_answer: message['is_ai_answer'],
      };

      let messageReplaced = false;
      var messageReplacedLength = 0;
      for (let i = 0; i < oldMessages.length; i++) {
        if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
          messageReplacedLength = oldMessages[i]['transcript'].length;
          oldMessages[i] = messageToAdd;
          messageReplaced = true;
        }
      }

      if (messageReplaced == false) {
        oldMessages.push(messageToAdd);
      }

      if (messageReplaced == true) {
        chatbotContextRef.current = chatbotContextRef.current.slice(
          0,
          -messageReplacedLength,
        );
      }

      chatbotContextRef.current += messageToAdd['transcript'];

      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          shouldScroll.current = true;
          if (shouldShowScrollButton) {
            setShouldShowScrollButton(false);
          }
        } else {
          if (!shouldShowScrollButton) {
            setShouldShowScrollButton(true);
          }
        }
      }
      setMessages(oldMessages);
      messagesRef.current = oldMessages;

      // make a copy of oldMessage using json parse and stringify
      // to avoid reference issues
      let messagesCopy = JSON.parse(JSON.stringify(oldMessages));

      updateMessagesMerged(messagesCopy);
    });
  };

  useEffect(() => {
    // get the room_id from the query params
    const searchParams = new URLSearchParams(location.search);
    const roomId = searchParams.get('room_id');

    const fetchRoomDetails = async (roomIdRef) => {
      try {
        const response = await axios.get(
          `${serverURL}/room/${roomIdRef.current}`,
          {
            headers: { Authorization: `Bearer ${jwtTokenRef.current}` },
          },
        );
        const roomData = response.data;
        passwordRef.current = roomData.password;

        const messagesList = roomData.messages
          ? JSON.parse(roomData.messages)
          : [];

        setMessages(messagesList);

        updateMessagesMerged(messagesList || []);
        // check if we should scroll
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
          const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
          const atBottom =
            Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
          shouldScroll.current = atBottom;
        }
      } catch (error) {
        console.error('Error fetching room details:', error);
      }
    };

    if (roomId !== null) {
      roomIdRef.current = roomId;
      fetchRoomDetails(roomIdRef);
    } else {
      if (localStorage.getItem('messages')) {
        // Check if the messages have timestmaps. If not, add them
        let messages = JSON.parse(localStorage.getItem('messages'));
        for (let i = 0; i < messages.length; i++) {
          if (messages[i]['timestamp'] == undefined) {
            const timestamp = new Date(messages[i]['timestamp'] * 1000);
            messages[i]['timestamp'] = timestamp.getTime();
          }
        }
        setMessages(messages);
        updateMessagesMerged(messages);
      }
    }
  }, [location.pathname]);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
    setShouldShowScrollButton(false);
  };

  const onArrowArrowLeft6CircleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    languageOneRef.current = languageOne;
    languageTwoRef.current = languageTwo;
  }, [languageOne, languageTwo]);

  useEffect(() => {
    joinRoomAsync();

    return () => {
      socket.off('load_messages');
      socket.off('receive_transcriptions');
    };
  }, []);

  useEffect(() => {
    return () => {
      stoppedTranscribing.current = true;
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      const expiryDateEpochSeconds =
        Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
      socket.emit('save_audio', {
        jwt_token: jwtTokenRef.current,
        clear_audio: true,
        expiry_date_epoch_seconds: expiryDateEpochSeconds,
      });
      pauseTimer();
      if (messagesRef.current.length > 0) {
        setMessages([...messagesRef.current]);
        updateMessagesMerged(messagesRef.current);
        localStorage.setItem('messages', JSON.stringify(messagesRef.current));
      }
    };
  }, [navigate]);

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    let lastScrollTop = messagesContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollTop = messagesContainer.scrollTop;
      if (currentScrollTop < lastScrollTop) {
        // User is scrolling up
        shouldScroll.current = false;
      }
      lastScrollTop = currentScrollTop;
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged]);

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectLanguage = (language) => {
    setIsOverlayOpen(false);
    if (language == 'Original' || language == 'Detect Language') {
      if (languageToSet == 'one') {
        setLanguageOne(language);
      } else if (languageToSet == 'two') {
        setLanguageTwo(language);
      }
      return;
    }
    if (languageToSet === 'one') {
      setLanguageOne(language);
    } else if (languageToSet === 'two') {
      if (language != languageTwoRef.current) {
        let textsToTranslate = [];
        let messagesAlreadyTranslated = [];
        for (let i = 0; i < messagesRef.current.length; i++) {
          let message = messagesRef.current[i];
          if (message['corrected_transcripts'][language] != undefined) {
            messagesAlreadyTranslated.push(i);
            continue;
          }
          textsToTranslate.push(message.transcript);
        }
        translateTexts(textsToTranslate, language).then((translations) => {
          let updatedMessages = [];
          for (let i = 0; i < messagesRef.current.length; i++) {
            let message = messagesRef.current[i];
            if (messagesAlreadyTranslated.includes(i)) {
              updatedMessages.push(message);
              continue;
            } else {
              if (message['corrected_transcripts'][language] == undefined) {
                message['corrected_transcripts'][language] =
                  translations[i] + ' ';
              }
              updatedMessages.push(message);
            }
          }
          setMessages(updatedMessages);
          updateMessagesMerged(updatedMessages);
          localStorage.setItem('messages', JSON.stringify(messagesMerged));
        });
      }
      setLanguageTwo(language);
    }
  };

  const onTranscribe = async (blob) => {
    if (userInfoRef.current.credits <= 10) {
      await handleTranscribeButton(true);
      setTranscribing(false);
      setIsOutOfCredits(true);
      return;
    }
    if (stoppedTranscribing.current) {
      clearChunks();
      stoppedTranscribing.current = false;
      return {
        blob,
        text: '',
      };
    }

    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    if (jwtTokenRef.current == undefined) {
      return;
    }
    let languagesToTranslateTo = [];
    if (
      languageOneRef.current != 'Detect Language' &&
      languageOneRef.current != 'Original'
    ) {
      languagesToTranslateTo.push(languageOneRef.current);
    }
    if (
      languageTwoRef.current != 'Detect Language' &&
      languageTwoRef.current != 'Original'
    ) {
      languagesToTranslateTo.push(languageTwoRef.current);
    }

    let transcribeRequest = {
      audio: base64,
      jwt_token: jwtTokenRef.current,
      translate_to_languages: languagesToTranslateTo,
    };

    if (roomIdRef.current !== undefined) {
      transcribeRequest['room_id'] = roomIdRef.current;
    }

    socket.emit('transcribe', transcribeRequest);
    clearChunks();

    // you must return result from your server in Transcript format
    return {
      blob,
      text: '',
    };
  };

  const { stopRecording, startRecording, clearChunks } = useWhisper({
    // callback to handle transcription with custom server
    onDataAvailable: onTranscribe,
    onTranscribe: onTranscribe,
    streaming: true,
    timeSlice: 250, // 5 seconds
    removeSilence: false,
    whisperConfig: {
      language: 'en',
    },
  });

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  const handleTranscribeButton = async (saving = false) => {
    if (isTranscribingRef.current || saving) {
      if (isTranscribingRef.current) {
        stoppedTranscribing.current = true;
        await stopRecording();
        pauseTimer();
        socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
        if (messagesRef.current.length > 0) {
          setMessages([...messagesRef.current]);
          updateMessagesMerged(messagesRef.current);
          localStorage.setItem('messages', JSON.stringify(messagesRef.current));
        }
      }
    } else {
      stoppedTranscribing.current = false;
      await startRecording();
      startTimer();
    }
    if (!saving) {
      setTranscribing(!isTranscribingRef.current);
    }
  };

  const playAudio = async (text) => {
    try {
      setIsLoadingTTS(true);
      if (playingAudio) {
        audioRef.current.pause();
        setPlayingAudio(false);
      }
      const response = await axios.post(
        `${serverURL}/text_to_speech?jwt_token=${jwtTokenRef.current}`,
        { text: text }, // Correctly sending the body as the second argument
        {
          responseType: 'blob', // Ensure the response is a blob
        },
      );

      if (response.status === 200) {
        setIsLoadingTTS(false);
        setPlayingAudio(true);
        const audioBlob = response.data;

        // Create a URL for the audio blob and handle it as needed
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        audioRef.current = audio;
        audio.onended = () => {
          setPlayingAudio(false);
        };
      } else {
        setIsLoadingTTS(false);
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      setIsLoadingTTS(false);
      console.error('Error occurred:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ type: 'tween', duration: 0.25 }}
      className='absolute z-20 w-full h-full flex items-center justify-center overflow-hidden'
    >
      {isOutOfCredits && (
        <OutOfCreditsWindow
          onClose={() => {
            setIsOutOfCredits(false);
          }}
          onBuyCredits={() => {
            setIsOutOfCredits(false);
            setIsSubscriptionsOpen(true);
          }}
        ></OutOfCreditsWindow>
      )}
      {isSubscriptionsOpen && (
        <PortalPopup>
          <Subscription
            closeFunc={() => {
              setIsSubscriptionsOpen(false);
            }}
            email={userInfoRef.current.email}
          ></Subscription>
        </PortalPopup>
      )}
      {showQRCode && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/20'>
          <RoomLinkAndQRCode
            link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
            onOutsideClickFunc={() => setShowQRCode(false)}
            divRef={linkAndQRCodeRef}
            isWeb={false}
            shareOnly={true}
          />
        </div>
      )}
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isSidebarVisible ? '0%' : '100%' }}
        transition={{ type: 'tween', duration: 0.5 }}
        className={`fixed top-0 right-0 w-80 h-full transition-colors shadow-md ${isSidebarVisible ? 'bg-black/30 duration-1000' : 'duration-0'} w-full`}
        style={{ zIndex: 100 }}
        onAnimationComplete={() => {}}
      >
        <RecentsSidebar
          onClose={() => {
            setIsSidebarVisible(false);
          }}
        />
      </motion.div>

      <div
        className={`bg-whitesmoke w-full h-full flex flex-col pt-14 sm:pt-[70px] px-2.5 sm:px-5 pb-0 sm:pb-[100px] box-border text-center text-xs text-updated-color-new-black font-montserrat relative`}
      >
        <div className='flex items-center w-full'>
          <img
            className='h-10 w-10 cursor-pointer'
            loading='lazy'
            alt=''
            src='/arrow--arrow-left-6-circle.svg'
            onClick={() => {
              stoppedTranscribing.current = true;
              pauseTimer();
              if (messagesRef.current.length > 0) {
                setMessages([...messagesRef.current]);
                updateMessagesMerged(messagesRef.current);
                localStorage.setItem(
                  'messages',
                  JSON.stringify(messagesRef.current),
                );
              }
              onArrowArrowLeft6CircleClick('/');
            }}
          />
          <div className='font-medium font-montserrat w-full mr-6'>
            Transcriber {formattedDate}
          </div>
          <img
            src='/recents.svg'
            alt='Recents'
            className='h-9 w-9'
            onClick={toggleSidebar}
          />
        </div>
        <div className='flex flex-row items-start justify-center w-full mt-4'>
          <div className='flex rounded-xl bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)] flex-row items-center justify-center py-1 px-2.5 box-border gap-[10px]'>
            <div className='flex-1 flex flex-row items-center justify-start gap-[5px]'>
              <div
                onClick={() => {
                  setIsOverlayOpen(true);
                  setLanguageToSet('one');
                }}
              >
                {languageOne}
              </div>
            </div>
            <img
              className='h-6 w-6 relative overflow-hidden shrink-0'
              loading='lazy'
              alt=''
              src='/media--playlist---repeat-list.svg'
              onClick={() => {
                let temp = languageOneRef.current;
                setLanguageOne(languageTwoRef.current);
                setLanguageTwo(temp);
              }}
            />
            <div className=' flex items-center justify-start gap-[5px]'>
              <div
                onClick={() => {
                  setIsOverlayOpen(true);
                  setLanguageToSet('two');
                }}
              >
                {languageTwo}
              </div>
            </div>
          </div>
        </div>
        <div
          className='flex-1 flex flex-col w-full overflow-y-auto items-start justify-start pt-0 px-0 pb-10 text-xs text-updated-color-new-black font-montserrat mt-4'
          ref={messagesContainerRef}
          onTransitionEnd={() => {
            if (shouldScroll.current) {
              // check if at the bottom
              const messagesContainer = messagesContainerRef.current;
              if (messagesContainer) {
                const { scrollTop, clientHeight, scrollHeight } =
                  messagesContainer;
                const atBottom =
                  Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
                if (atBottom) {
                  shouldScroll.current = false;
                }
              }
            }
          }}
        >
          <div className='flex flex-col w-full gap-2.5 text-left'>
            {messagesMerged.map((message, index) => (
              <Message
                key={index}
                showOriginal={showOriginal}
                message={message}
                languageOne={languageOne}
                languageTwo={languageTwo}
                isLoadingTTS={isLoadingTTS}
                playAudio={playAudio}
              />
            ))}
          </div>
        </div>
        <div className='self-stretch relative flex flex-col items-start justify-start text-left text-xs text-updated-color-new-black font-montserrat'>
          <div
            className={`w-full flex items-center justify-end absolute -top-10 py-2 pr-1 backdrop-blur-sm bg-white/30`}
          >
            <div className='mr-auto'>
              <Switch
                isOn={liveAudioEnabled}
                handleToggle={toggleLiveAudio}
                onColor='bg-dodgerblue-200'
                text='Live Audio'
              />
            </div>
            {shouldShowScrollButton && (
              <img
                className='w-[34px] rounded-full mx-auto absolute top-2 left-0 right-0 h-[34px]'
                loading='lazy'
                alt=''
                src='/arrow--arrow-down-6-circle.svg'
                onClick={() => {
                  scrollToBottom();
                }}
              />
            )}
            <div className='flex items-center gap-1.5'>
              {roomIdRef.current && (
                <img
                  src='/share.svg'
                  alt='Share'
                  className='w-6 h-6'
                  onClick={() => {
                    setShowQRCode(true);
                  }}
                />
              )}
              <div className='text-xs font-medium text-center'>AI Chat</div>
              <img
                src='/chai2.svg'
                alt='Chatbot'
                className='w-8 h-8'
                onClick={() => {
                  setIsChatbotOpen(true);
                }}
              />
              {!copied ? (
                <img
                  className='w-6 h-6 overflow-hidden'
                  alt='Copy button'
                  src='/files--copy.svg'
                  onClick={() => {
                    copyMessagesToClipboard(languageTwo);
                  }}
                />
              ) : (
                <CheckOutlined className='text-2xl' />
              )}
              <img
                className='w-6 h-6 overflow-hidden'
                alt='Delete button'
                src='/trash.svg'
                onClick={async () => {
                  // get the room_id from the query params
                  if (roomIdRef.current !== null) {
                    await axios.post(
                      `${serverURL}/room/${roomIdRef.current}/delete_messages`,
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${jwtTokenRef.current}`,
                        },
                      },
                    );
                  }
                  localStorage.removeItem('messages');
                  messagesRef.current = [];
                  if (messagesMerged.length > 0) {
                    setMessagesMerged(() => []);
                  }
                  if (messages.length > 0) {
                    setMessages(() => []);
                  }
                }}
              />
            </div>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start pt-2 px-0 pb-10 gap-[10px]'>
            <div className='flex justify-center items-center w-full gap-2'>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
              <div className='text-xs font-medium font-montserrat text-updated-color-new-black text-center inline-block'>
                {formatTime(elapsedTime)}
              </div>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
            </div>
            <div className='w-full flex justify-center gap-10 pl-4 mt-2'>
              <div
                className={`shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex items-center justify-center h-fit p-3 border-[0.5px] border-solid border-updated-color-grey1`}
                onClick={() => {
                  if (messagesMerged.length > 0) {
                    setShowPopup(true);
                  }
                }}
              >
                Save
              </div>
              <div className='w-fit h-fit flex items-center justify-center'>
                {!isTranscribing ? (
                  <div
                    className={`self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-white flex flex-row items-center justify-center p-1 px-5 max-h-[40px] my-auto border-[0.5px] border-solid border-updated-color-grey1`}
                    onClick={() => {
                      if (userInfoRef.current.credits <= 10) {
                        setIsOutOfCredits(true);
                        return;
                      }
                      handleTranscribeButton();
                    }}
                  >
                    <img
                      className='h-7 w-7 relative overflow-hidden shrink-0'
                      loading='lazy'
                      alt=''
                      src='/media--player---play.svg'
                    />
                  </div>
                ) : (
                  <div
                    className={`self-stretch shadow-[0px_3px_5px_rgba(140,_140,_140,_0.25)] rounded-12xl bg-updated-color-red flex flex-row items-center justify-center py-[10px] max-h-[40px] px-[32px] my-auto border-[0.5px] border-solid border-updated-color-grey1`}
                    onClick={() => handleTranscribeButton()}
                  >
                    <img
                      className='h-7 w-7 relative overflow-hidden shrink-0 mx-auto'
                      loading='lazy'
                      alt=''
                      src='/media--player---pause.svg'
                    />
                  </div>
                )}
              </div>
              <div className='w-fit flex flex-col items-center justify-center text-center text-[10px]'>
                <div
                  className='h-[33px] w-[50px] relative cursor-pointer'
                  onClick={() => {
                    setShowOriginal(!showOriginal);
                  }}
                >
                  <div
                    className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[31px] ${
                      showOriginal
                        ? '[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
                        : 'bg-gray-300'
                    }`}
                  />
                  <div
                    className={`absolute h-[75.76%] w-6/12 top-[12.12%] bottom-[12.12%] rounded-[50%] bg-white ${
                      showOriginal
                        ? 'right-[12%] left-[38%]'
                        : 'right-[38%] left-[12%]'
                    }`}
                  />
                </div>
                <div className='mt-2 text-xs font-medium text-center w-fit'>
                  Show Original
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOverlayOpen && (
        <div className='absolute top-0 left-0 w-full h-full'>
          <RoomSelectLanguageOverlay
            onClose={() => setIsOverlayOpen(false)}
            onSelect={selectLanguage}
          />
        </div>
      )}
      {showPopup && (
        <SavePopup
          stoppedTranscribing={stoppedTranscribing}
          socket={socket}
          handleTranscribeButton={handleTranscribeButton}
          jwtTokenRef={jwtTokenRef}
          setShowPopup={setShowPopup}
          isTranscribing={isTranscribing}
        />
      )}
      <div style={{ display: isChatbotOpen ? 'block' : 'none' }}>
        <MobileChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
        />
      </div>
    </motion.div>
  );
};
